import { createSlice } from "@reduxjs/toolkit";

export const AllUsersSlice=createSlice({
    name:"allUsers",
    initialState:{
        value:[]
    },
    reducers:{
        getAllUsers:(state,action)=>{
            state.value=action?.payload?.allUsers
        }
    }
})

export const {getAllUsers}=AllUsersSlice.actions;
export default AllUsersSlice.reducer