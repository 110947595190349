import React from 'react'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
    const navigate = useNavigate()
  return (
    <div className='notFound'>
        <div className="imageContainer">
            <img src={require("../../assets/Images/notFound.png")} alt="" />
        </div>
        <div className="content">
            <h1>404</h1>
            <p>The program you are looking for may have moved or is not currently available, please try searching for it in the search bar or contact support for help.</p>
            <div className="buttonContainer" onClick={()=>navigate("/home")} >
                <div className="background"></div>
                <button>Back to home</button>
            </div>
        </div>
    </div>
  )
}

export default NotFound