import { updateApiData } from "../../utils/utils";

export const updateContactSuppport = async (appInfo,values) => {
    const api = "user/support/send";
    const data = {
        'name': values?.name,
        'email': values?.email,
        'pubid': appInfo?.projectDetails?.projectConfig?.pubid,
        'state': values?.state,
        'reason':values?.issues,
        'country': values?.country,
        'title': values?.movie,
        'platform': values?.platform,
        'topic': values?.topic,
        'message': values?.message,
        'country_code':appInfo?.projectDetails?.geoInfo?.countryCode
    }
    const location = {
        path:"common",
        values:data
    }
    return await updateApiData(appInfo,api,location)
}