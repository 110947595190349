import {
    ProgramBox,
    ProgramContent,
    ProgramFlex,
    ProgramStack,
    ProgramTitle,
    ProgramText,
    ProgramImage,
    useProgram
  } from "planby";
import { useDispatch, useSelector } from "react-redux";
import { getScheduleModal } from "../../../Redux/ScheduleModal/scheduleModalSlice";
import { convertTimeToLocal } from "../../../utils/utils";
import { getSelectedSchedule } from "../../../Redux/SelectedScheduleChannel/selectedScheduleChannelSlice";
import { useEffect } from "react";
  
  export const ProgramItem = ({ program, ...rest}) => {
    const {
      styles,
      formatTime,
      set12HoursTimeFormat,
      isLive,
      isMinWidth
    } = useProgram({
      program,
      ...rest
    });
    const dispatch = useDispatch()
    const { data } = program;
    const { image, title, since, till } = data;
    const sinceTime = formatTime(since, set12HoursTimeFormat()).toLowerCase();
    const tillTime = formatTime(till, set12HoursTimeFormat()).toLowerCase();
    // const sinceTime = convertTimeToLocal(since).toString().toLowerCase();
    // const tillTime = convertTimeToLocal(till).toString().toLowerCase();
    const selectedSchedule = useSelector((state) => state?.selectedSchedule?.value);
 
    useEffect(()=>{
    if(isLive){
      let initialSchedule =localStorage.getItem("initialScheduleAdded")? localStorage.getItem("initialScheduleAdded"):"false"
      if(initialSchedule === "false" || initialSchedule === null){
        dispatch(
          getSelectedSchedule({
            selectedSchedule:data
          })
        )
      localStorage.setItem("initialScheduleAdded","true")
      }else{
        if(selectedSchedule?.channel_id === data?.channel_id){
          if(isLive){
            dispatch(
              getSelectedSchedule({
                selectedSchedule:data
              })
            )
          }
        }

      }
    }
    
  },[isLive,selectedSchedule])
    const liveShowHandler = () => {
    if(isLive){
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      dispatch(
        getSelectedSchedule({
          selectedSchedule:data
        })
      )
    }
      // else{
      //   let scheduleData={
      //     isOpen:true,
      //     data:data
      //   }
      //   dispatch(
      //     getScheduleModal({
      //       scheduleModal:scheduleData,
      //     })
      //   )

      // }
  }
    return (
      <ProgramBox width={styles.width} style={styles.position} onClick={liveShowHandler}> 
        <ProgramContent width={styles.width} isLive={isLive}>
          <ProgramFlex>
          {/* <ProgramImage src={image} alt="Preview" /> */}
            {isLive && isMinWidth && <ProgramImage src={image} alt="Preview" />}
            <ProgramStack>
              <ProgramTitle>{title}</ProgramTitle>
              <ProgramText>
                {sinceTime} - {tillTime}
              </ProgramText>
            </ProgramStack>
          </ProgramFlex>
        </ProgramContent>
      </ProgramBox>
    );
  };
  