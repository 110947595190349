import axios from "axios";
import { updateApiData } from "../../../utils/utils";
const qs = require("query-string");

export const login = async (loginValues, appInfo) => {
  let token = appInfo?.accessToken;
  let uid = localStorage.getItem("gid");
  let user_id = localStorage.getItem("userId");
  let countryCode = appInfo?.projectDetails?.countryCode;
  let ipaddress = appInfo?.projectDetails?.query;
  let deviceId = appInfo?.projectDetails?.device_id;
  let pubid = appInfo?.projectDetails?.projectConfig?.pubid;
  let channelid = appInfo?.projectDetails?.projectConfig?.channelid;
  if (user_id) {
    uid = user_id;
  }
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
      uid: uid,
      pubid: pubid,
      country_code: countryCode,
      channelid: channelid,
      dev_id: deviceId,
      ip: ipaddress,
      device_type: "web",
    },
  };
  var url = new URL(process.env.REACT_APP_API_URL + "account/login"),
    params = {
      user_email: loginValues?.email,
      password: loginValues?.password,
    };
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key])
  );
  let res = {};
  let response = await fetch(url, customConfig);
  res.status = response?.status;
  res.data = await response.json();
  return res;
};

export const googleLogin = async (token) => {
  return await axios.get(
    `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
};

export const updateLoginWithoutCode = async (appInfo,values)=>{
  const api = "account/social/loginWithoutCode"
  const gid=localStorage.getItem("gid"); 

  const data = {
    google_id:values?.googleId,
    facebook_id:values?.facebookId,
    apple_id:values?.appleId,
    login_type:values?.loginType,
    first_name:values?.firstName,
    last_name:values?.lastName,
    email:values?.email,
    guest_user_id:gid
  }
  const location = {
    path:"common",
    values:data
  }
  return await updateApiData(appInfo,api,location);
}

export const sendEmailLoginRequest = async (appInfo,email,code) => {
  const api = "account/request/send"
  const data={
    email,
    code,
  }
  const location = {
    path:"common",
    values:data
  }
  return await updateApiData(appInfo,api,location);

}


