import React, { useEffect, useState } from 'react'
import LoginRequestModal from '../../Components/Modals/LoginModal/LoginRequestModal'
import { getLoginRequestDetails } from './service'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const LoginRequest = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const [loading, setLoading] = useState(true)
  const [requestInfo, setRequestInfo] = useState({})
  const location = useLocation()
  const params = new URLSearchParams(location?.search)
  const token = params.get("token")
  const expiresIn = params.get("expiresIn")


  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken
  }

  useEffect(() => {
    if (token && expiresIn) {
      setLoading(true)
      fetchLoginRequestDetails()
    }
  }, [token, expiresIn])

  const fetchLoginRequestDetails = async () => {
    const values = {
      token,
      expiresIn
    }
    try {
      const response = await getLoginRequestDetails(appInfo, values)
      if (response?.status === 200) {
        setRequestInfo(response?.data?.data)
        setLoading(false)
      }

    } catch (err) {
      setLoading(false)
    }
  }
  
  return (
    <div className='loginRequest'>
      <LoginRequestModal appInfo={appInfo} data={requestInfo} loading={loading} />
    </div>
  )
}

export default LoginRequest