import React, { useEffect, useState } from 'react'
import { ReactComponent as CloseIcon } from "../../assets/Icons/cross.svg";
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguageModal } from '../../Redux/LanguageModal/languageModalSlice';
import { getLanguageList, updateUserLanguage } from '../../network/service';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../Redux/UserDetailsSlice/UserDetailsSlice';
const LanguageModal = ({type}) => {
  const projectInfo=useSelector((state)=>state?.projectInfo?.value);
  const accessToken=useSelector((state)=>state?.accessToken?.value);

  const selectedLanguageData = useSelector((state) => state?.languageModal?.value);
  const userDetails = useSelector((state) => state?.userDetails?.value);
    
    const [selectedValue,setSelectedValue] = useState()
    const [initialSelectedValue,setInitialSelectedValue] = useState()
    const dispatch = useDispatch();
    const [options,setOptions] = useState()
    const location = useLocation()
    const appInfo={
      projectDetails:projectInfo,
      accessToken:accessToken
    }
    const navigate = useNavigate()
    const redirectURL = localStorage.getItem("redirectURL")
    const getOptionLabel = (option) => option.language_name;
    const getOptionValue = (option) => option.language_id;
    useEffect(()=>{
      console.log(selectedLanguageData,"selected language data");

        fetchOptions().then((data)=>{
            setOptions(data)
            if(userDetails && userDetails?.language_id){
                const initialValue = data?.filter((option)=>option?.language_id===userDetails?.language_id)
                setSelectedValue(initialValue[0])
            }else{
                let defaultValue = data?.filter((option)=>option?.language_id===39)
                setSelectedValue(defaultValue[0])
            }
        })

    
        return () => localStorage.removeItem("redirectURL")
    },[userDetails])


    const closeHandler = () => {
        dispatch(
            getLanguageModal({
                languageModal:{
                    isOpen:false,
                    selectedLanguage:null
                }
            })
        )
    }

    const fetchOptions = async () => {
        return new Promise(async (resolve) => {
          try{
            const response = await getLanguageList(appInfo)
            resolve(response?.data?.data)
          }catch(err){

          }
        });
       
      
      };

    const handleChange = (selectedValue) => {
        setSelectedValue(selectedValue)
    }

    const submitButtonHandler = () => {
        if(selectedValue?.language_id){
           updateUserLanguageData()
        }else{
          toast.error("Please select a language!",{
            position:"top-center"
          })
        }
    }

    const updateUserLanguageData = async () =>{
      try{
        const response = await updateUserLanguage(appInfo,selectedValue?.language_id)
        if(response?.status === 200){
          dispatch(
            getUserDetails({
                userDetails:{...userDetails,language_id:selectedValue?.language_id}
            }) 
        )
        //   dispatch(
        //     getLanguageModal({
        //         languageModal:{
        //             isOpen:false,
        //             selectedLanguage:selectedValue
        //         }
        //     }) 
        // )
        if(type==="root"){
          if(redirectURL){
            navigate(redirectURL,{replace:true})
          }else{
            navigate("/home")
          }
        }else{

          dispatch(
            getLanguageModal({
                languageModal:{
                    isOpen:false,
                    selectedLanguage:selectedValue?.language_id
                }
            })
        )
        }
          toast.success(response?.data?.message,{
            position:"top-center"
          })
        }
      }catch(err){

      }
      
    }
   
  return (
    <div className='languageModal'>
      <div className={type==="root"?"overlayModal root":"overlayModal"}></div>
     {
     type!=="root"&&
     <div className="closeIconContainer" onClick={closeHandler}>
        <CloseIcon />
      </div>}

      <div className="contents">
        <h1>Choose preferred video Language</h1>
        <div className="inputContainer">
        <Select
        className="basic-single"
        classNamePrefix="select"
        value={selectedValue}
        isSearchable={true}
        name="color"
        options={options}
        onChange={handleChange}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
      />
        </div>
        <div className="buttonContainer" onClick={submitButtonHandler}>
            <button>OK</button>
        </div>
      </div>
    </div>
  )
}

export default LanguageModal