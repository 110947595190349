import React, { useEffect, useState } from "react";
import { ReactComponent as LikeIcon } from "../../../assets/Icons/like.svg";
import { ReactComponent as ReplyIcon } from "../../../assets/Icons/reply.svg";
import { ReactComponent as FlagIcon } from "../../../assets/Icons/flag.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/Icons/delete.svg";

import ReplyField from "./ReplyField";
import { increment, onValue, push, ref,set,update } from "firebase/database";
import initFirebase from "../../../firebaseConfig";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getCurrentUTCTimestamp, timeDifferenceWithCurrenttime } from "../../../utils/utils";
const firebaseDB = initFirebase()
const CommentReplies = ({childReplyFieldHandler,mainReplyField,data,replies,setIsReplyField}) => {
  const user = useSelector((state) => state?.user?.value);
  const [liked,setLiked] = useState(false)
  const [repliedUser,setRepliedUser] = useState({})
  const [repliedTime,setRepliedtime] = useState("")
  useEffect(() => {
    const usersDbRef = ref(firebaseDB, `users/${replies?.user_id}`);

    userHasLiked()
    onValue(usersDbRef, (snapshot) => {
      const data = snapshot.val()
      setRepliedUser(data)
      // const username = (snapshot.val() && snapshot.val().username) || 'Anonymous';
      // ...
    }, {
      onlyOnce: true
    });
    setRepliedtime(timeDifferenceWithCurrenttime(replies?.created_at))
    let timedifferenceCalculation = setInterval(() => {
      setRepliedtime(timeDifferenceWithCurrenttime(replies?.created_at))
    }, 20*1000);
    return () => clearInterval(timedifferenceCalculation)
  }, [data,replies])
  

  const replyFieldHandler = () => {
    if(!mainReplyField){
        setIsReplyField(true)
        childReplyFieldHandler(true)
    }
  }

  const likeHandler = () => {
    // const 
    const dbRef = ref(firebaseDB,`comments/${data?.videoId}/${data?.id}/replies/${replies?.replyId}`)
    const dbRefLikedUsers = ref(firebaseDB,`comments/${data?.videoId}/${data?.id}/replies/${replies?.replyId}/liked_users/${user}`);
    update(dbRef, {
      likes: increment(liked?-1:1),
    });
   
    set(dbRefLikedUsers, liked?0:1);
  }
  const userHasLiked = () => {
    if(replies&&replies?.liked_users&&replies?.liked_users[user]){
      setLiked(true)
    }else{
      setLiked(false)
    }
  }
  const reportHandler = () => {
    if(userHasReported()===false){
      const dbRef = ref(firebaseDB, `comments/${data?.videoId}/${data?.id}/replies/${replies?.replyId}`);
      const dbRefLikedUsers = ref(firebaseDB, `comments/${data?.videoId}/${data?.id}/replies/${replies?.replyId}/reported_users/${user}`);
      update(dbRef, {
        reported_count: increment(1),
      })
      set(dbRefLikedUsers,1);
      toast.success("Successfully reported", {
        position: "top-center",
      });
    }else{
      toast.error("Already reported", {
        position: "top-center",
      });
    }
  };
  const userHasReported = () => {
    if(replies?.reported_users&&replies?.reported_users[user]){
      return true
    }else{
      return false
    }
  }
  const deleteReplyhandler = () => {
    const dbRef = ref(firebaseDB, `comments/${data?.videoId}/${data?.id}/replies/${replies?.replyId}`);
    const dbRefReplies = ref(firebaseDB,`comments/${data?.videoId}/${data?.id}`)
    update(dbRefReplies, {
      reply_count: increment(-1),
    });
    update(dbRef, {
      delete_status: true,
      deleted_at: getCurrentUTCTimestamp(),
    });
  };
  return (
    <div className="replyMessagesContainer">
      <div className="containers">
        <div className="left">
        {
              repliedUser?.image ? (
                <div className="profileImageContainer">
                  <img src={repliedUser?.image} alt="Profile" />
                </div>
              ):(
                <span className="nameAvatar">{repliedUser?.name?.slice(0, 1)?.toUpperCase()}</span>

              )
            }
        </div>
        <div className="right">
          <h1 className="name">{repliedUser?.name}</h1>
          <span className="time">{repliedTime}</span>
          <p className="comment">{replies?.comment_text}</p>
          <div className="actions">
            <span className="item" onClick={likeHandler}>
              <span className="icon">
                <LikeIcon style={liked?{fill:"red"}:{}}/>
              </span>
              {
                replies?.likes>0&&replies?.likes
              } 
              {" "}
              {
                replies?.likes>=2?"Likes":"Like"
              }
            </span>
            <span className="item" onClick={()=>replyFieldHandler()}>
              <span className="icon">
                <ReplyIcon />
              </span>
              Reply
            </span>
            {
              replies?.user_id === Number(user)?(
                <span className="item" onClick={() => deleteReplyhandler()}>
                <span className="icon">
                  <DeleteIcon />
                </span>
                Delete
              </span>
              ):(
                <span className="item" onClick={reportHandler}>
                <span className="icon">
                  <FlagIcon />
                </span>
                Report
              </span>
              )
            }
         
          </div>
        </div>
      </div>
      {/* {
        isReplyField &&
      <ReplyField data={data} type="innerComment" setIsReplyField={setIsReplyField} childReplyFieldHandler={childReplyFieldHandler}/>
      } */}
    </div>
  );
};

export default CommentReplies;
