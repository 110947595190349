import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import ShowCard from "../ShowCard/ShowCard";
import { ReactComponent as Arrow } from "../../assets/Icons/filledArrow.svg";
import { useSelector } from "react-redux";
import PdfCard from "../PdfCard/PdfCard";

              
const ShowsRow = ({ season, data,type, title, metaData,watchVideoHandler,setVideoPlayer,similarVideos,thumbnailOrientation,b2b,playVideo,singleEpisode }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  
  const [seasonButton, setSeasonButton] = useState(false);
  const [details, setDetails] = useState();
  const [releaseDateWiseData, setReleaseDateWiseData] = useState();
  const [seasons, setSeasons] = useState([]);
  const [seasonName,setSeasonName] = useState("Season 1")
  const [selectedSort,setSelectedSort] = useState("default")
  useEffect(() => {
    if(type === "pdf"){
      setDetails(data)
    }else{
      if (data && !season) {
        setDetails(data);
        setReleaseDateWiseData(data)
      } else {
          setDetails(data[0]?.videos)
        setReleaseDateWiseData(data[0]?.videos)
        setSeasons(data);
      }

    }
  }, [data]);
 
  const sortHandler = (sortType) => {
    if(sortType === "default"){
      return (
        details?.sort((a,b)=> a?.video_order - b?.video_order)?.map((item,index)=>(
          <SwiperSlide key={index}>
          
          <div className="show" onClick={()=>similarVideos?setVideoPlayer(null):watchVideoHandler(item)}>
            <ShowCard
              season={season ? true : false}
              data={item}
              imageUrl={true}
              metaData={metaData ? true : false}
              type={type}
              b2b={b2b}
              playVideo={playVideo}
              minHeight={"none"}


            />
          </div>
        </SwiperSlide>
        ))
      )
    }else if(sortType === "alphabhetical"){
      return (
        details?.sort((a,b)=> a.video_title > b.video_title?1:-1)?.map((item,index)=>(
          <SwiperSlide key={index}>
          <div className="show" onClick={()=>similarVideos?setVideoPlayer(null):watchVideoHandler(item)}>
            <ShowCard
              season={season ? true : false}
              data={item}
              imageUrl={true}
              metaData={metaData ? true : false}
              type={type}
              b2b={b2b}
              playVideo={playVideo}
              minHeight={"none"}
            />
          </div>
        </SwiperSlide>
        ))
      )
    }else if(sortType === "date"){
      return(
        releaseDateWiseData?.map(obj => {
          return {...obj,schedule_date:new Date(obj?.schedule_date)}
        })?.sort((a,b)=> a?.schedule_date -b?.schedule_date)?.map((item,index)=>(
          <SwiperSlide key={index}>
          <div className="show" onClick={()=>similarVideos?setVideoPlayer(null):watchVideoHandler(item)}>
            <ShowCard
              season={season ? true : false}
              data={item}
              imageUrl={true}
              metaData={metaData ? true : false}
              type={type}
              b2b={b2b}
              playVideo={playVideo}
              minHeight={"none"}


            />
          </div>
        </SwiperSlide>
        ))
      )
    }
  }

  const sortHandleChange = (item) => {
    const {value } = item?.target;
    // if(value === "alphabhetical"){
    //   let sortedArray = details?.sort((a,b)=> a.video_title > b.video_title?1:-1)
    //   setDetails(sortedArray)
    // }else if (value === "date"){
    //   let sortedArray = releaseDateWiseData?.map(obj => {
    //     return {...obj,schedule_date:new Date(obj?.schedule_date)}
    //   })?.sort((a,b)=> a?.schedule_date -b?.schedule_date)

    //   setDetails(sortedArray)
    // }else{
    //   let sortedArray =  details?.sort((a,b)=> a?.video_order - b?.video_order);
    //   setDetails(sortedArray)
    // }
    setSelectedSort(value)
  }
  return (
    <div className="showsRow">
        <div className="head">
          {title && <h1 className="heading">{title}</h1>}
          {
        type === "episodes" && 
       <div className={singleEpisode ? "sortContainerHide" : "sortContainer"}>
        <select name="sort" id="sort" onChange={(item)=> sortHandleChange(item)}>
          <option value="default" selected>Sort by</option>
          <option value="alphabhetical">Alphabetical</option>
          <option value="date">Release date</option>
        </select>
      </div>
      }
        </div>
     
      {season && (
        <div
          className={seasonButton?"seasonContainer zIndexController":"seasonContainer"}
          onClick={() => setSeasonButton(!seasonButton)}
        >
          <span className="selected">{seasonName}</span>
        <div className={season?.length>5?"listContainer scroll":"listContainer"}>

          <div className={seasonButton ? "list active" : "list"}>
            {seasons?.map((item, index) => (
              <div className="item" key={index} onClick={()=>{setDetails(item?.videos);setSeasonName(item?.season)}}>
                <span>{item?.season}</span>
              </div>
            ))}
           
            
          </div>
        </div>
          <Arrow className={seasonButton ? "active" : "no-active"} />
        </div>
      )}


       
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={10}
        slidesPerView={4}
        navigation={true}
        watchSlidesProgress
        loop={false}
        breakpoints={{
          320: {
            slidesPerView: thumbnailOrientation==="PORTRAIT"?3:1,
            spaceBetween: 5,
          },
          768: {
            slidesPerView: thumbnailOrientation==="PORTRAIT"?4:2,
            spaceBetween: 10,
          },
          980: {
            slidesPerView:  projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT?projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT:4,
            spaceBetween: 10,
          },
        }}
      >
        {
          type === "episodes"?(
            sortHandler(selectedSort)
          ):
          type === "pdf"?(
            details?.map((item,index)=>(
              <SwiperSlide key={index} >
                <PdfCard data={item}/>
              </SwiperSlide>
            ))
          ):
          (
            details?.map((item,index)=>
            <SwiperSlide key={index}>
            <div className="show" onClick={()=>similarVideos?setVideoPlayer(null):watchVideoHandler(item)}>
              <ShowCard
                season={season ? true : false}
                data={item}
                imageUrl={true}
                metaData={metaData ? true : false}
                type={type}
              minHeight={"none"}

              />
            </div>
          </SwiperSlide>
            )
          )
        }
      </Swiper>
      {/* {
        selectedSort === "alphabhetical"&&
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={10}
        slidesPerView={4}
        navigation={true}
        watchSlidesProgress
        loop={false}
        breakpoints={{
          320: {
            slidesPerView: thumbnailOrientation==="PORTRAIT"?3:1,
            spaceBetween: 5,
          },
          768: {
            slidesPerView: thumbnailOrientation==="PORTRAIT"?4:2,
            spaceBetween: 10,
          },
          980: {
            slidesPerView:  projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT?projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT:4,
            spaceBetween: 10,
          },
        }}
      >
        {
          details?.sort((a,b)=> a?.video_title > b?.video_title?1:-1)?.map((item,index)=>(
            <SwiperSlide key={index}>
            <div className="show" onClick={()=>similarVideos?setVideoPlayer(null):watchVideoHandler(item)}>
              <ShowCard
                season={season ? true : false}
                data={item}
                imageUrl={true}
                metaData={metaData ? true : false}
                type={type}
              />
            </div>
          </SwiperSlide>
          ))
        }
      </Swiper>
      }

      {
        selectedSort === "date" &&
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={10}
        slidesPerView={4}
        navigation={true}
        watchSlidesProgress
        loop={false}
        breakpoints={{
          320: {
            slidesPerView: thumbnailOrientation==="PORTRAIT"?3:1,
            spaceBetween: 5,
          },
          768: {
            slidesPerView: thumbnailOrientation==="PORTRAIT"?4:2,
            spaceBetween: 10,
          },
          980: {
            slidesPerView:  projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT?projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT:4,
            spaceBetween: 10,
          },
        }}
      >
        {
          releaseDateWiseData?.map(obj => {
            return {...obj,schedule_date:new Date(obj?.schedule_date)}
          })?.sort((a,b)=> a?.schedule_date -b?.schedule_date)?.map((item,index)=>(
            <SwiperSlide key={index}>
            <div className="show" onClick={()=>similarVideos?setVideoPlayer(null):watchVideoHandler(item)}>
              <ShowCard
                season={season ? true : false}
                data={item}
                imageUrl={true}
                metaData={metaData ? true : false}
                type={type}
              />
            </div>
          </SwiperSlide>
          ))
        }
      </Swiper>
      } */}

    </div>
  );
};

export default ShowsRow;
