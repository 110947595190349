import axios from "axios";
import { fetchApiData, fetchApiDataV2, updateApiDataV2, fetchPublicApi } from "../../utils/utils";

export const getVideoDetails = async (id,appInfo) =>{
    const api =`video/${id}`
    return await fetchApiDataV2(appInfo,api)
}

export const updateB2BViewCOunt=async (appInfo,id)=>{
    const api = "b2b/updateCount";
    const data = {id};
    const location = {
      path:"common",
      values:data
  }
    return await updateApiDataV2(appInfo, api, location);    
  }

  export const getRedirectDetails = async () => {
    const videoScreenURL = window.location.href
    const api = `redirect?url=${videoScreenURL}`
    return await fetchPublicApi(api)
}
