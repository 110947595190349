import React from 'react'
import { useNavigate } from 'react-router-dom'
import { convertAdUrl } from '../../utils/utils'

const WatchWithoutAdsModal = (props) => {

    const navigate= useNavigate()
    const watchWithoutAdsHandler = async (value) => {

        if(value === "yes"){
          if(props?.directSubscribeState?.flag){
            props?.handleSubscribe(props?.directSubscribeState?.data)
          }else{

            navigate("/subscription" ,{state:{videoId:props?.videoDetails?.video_id}})
          }
        }else if (value === "no"){
       
          if(props.videoDetails.watched_duration > 0){
              props?.setIsContinueWatching(true);
                props?.setWatchWithoutAds(false);  
          }else{
            props.setIsContinueWatching(false) 
              props?.setAdUrl(convertAdUrl(props?.videoDetails,props?.showDetails,props?.appInfo,props?.isLive))
            props?.playVideo(props?.videoDetails)
            props?.setWatchWithoutAds(false)  
          }
        }
    }
  return (
    <div className="watchWithoutAds">
      <div className="overlayModal"></div>
      <div className="contents">
        <h1>Watch without ads?</h1>
        <div className="buttons">
            <div className="buttonContainer" onClick={()=>watchWithoutAdsHandler("yes")}>
            <div className="background"></div>
            <button >Yes</button>
            </div>

            <div className="buttonContainer" onClick={()=>watchWithoutAdsHandler("no")}>
            <div className="background"></div>
            <button >No</button>
            </div>

        </div>
      </div>
    </div>
  )
}

export default WatchWithoutAdsModal