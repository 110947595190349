import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import Loading from '../../Components/Loading/Loading';
import Categories from './Components/Categories';
import Featured from './Components/Featured';
import Live from './Components/Live';
import * as service from "./service";
import { checkOsVersion } from '../../utils/utils';
import useScrollPosition from '../../Components/ScrollPosition/useScrollPosition';
import FeaturedBanner2 from './Components/FeaturedBanner2';
import { useNavigate } from 'react-router-dom';
import { getUserSubscription } from '../../network/service';
import { getUserSubscriptionData } from "../../Redux/UserSubscriptionDetails/userSubscriptionSlice";
import { useDispatch } from 'react-redux';
const Homepage = () => {
  const projectInfo=useSelector((state)=>state?.projectInfo?.value);
  const accessToken=useSelector((state)=>state?.accessToken?.value);
  const user=useSelector((state)=>state?.user?.value);
  const userDetails = useSelector((state) => state?.userDetails?.value);
  const thumbnailOrientation = projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION;

  const [loading ,setLoading] = useState(true)
  const [homeData,setHomeData] = useState([])
  const [homeDataSliceCount, setHomeDataSliceCount] = useState(8);

  const homePageContainerRef = useRef()
  const scrollPosition = useScrollPosition();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const appInfo={
    projectDetails:projectInfo,
    accessToken:accessToken
  }

  useEffect(()=>{
    if(userDetails?.language_id ===null || !userDetails?.language_id || userDetails?.language_id === ""){
      navigate("/language-selection")
      localStorage.setItem("redirectURL","/home")
    }
  },[userDetails])

  useEffect(()=>{
    if(!loading && homeDataSliceCount<homeData?.length){
      lazyLoad()
    }
  },[loading,scrollPosition,homeData])

  useEffect(()=>{
    window.scroll(0,0)
   setLoading(true)
     fetchShowDetails();
  },[user])

  useEffect(() =>{
    fetchUserSubscriptionDetails();
  },[]);

  const fetchShowDetails = async()=>{
    try{
      const response = await  service.getShowsByCategory(appInfo);
      if(response?.status === 200){
        setHomeData(response?.data?.data)
        setLoading(false)
      }
    }
    catch{
      setLoading(true)
    }
  }

  const lazyLoad = () => {
    const containerHeight = homePageContainerRef?.current?.clientHeight;
    if(scrollPosition + 2500 >= containerHeight){
      setHomeDataSliceCount(homeDataSliceCount+5)
    }
  }
  const fetchUserSubscriptionDetails = async () => {
    const userSubResponse = await getUserSubscription(appInfo);
    if (userSubResponse?.status === 200) {
      dispatch(
        getUserSubscriptionData({
          userSubscription: userSubResponse?.data,
        })
      );
    }
  };

if(loading){
  return(
    <Loading/>
  )
}
  return (
    <div className='homePage' ref={homePageContainerRef}>
        {
          homeData?.slice(0,homeDataSliceCount).map((item,index)=>{
            if(item?.type==="LIVE") {
              return <Live key={index} data={item}/>
            } 
            // else if(item?.type==="FEATURED") {
            //   return <Featured key={index} data={item} />
            // }
            else if(item?.type==="FEATURED") {
                return (
                <FeaturedBanner2 key={index} data={item} />
                )
              }
            else{
              return <Categories thumbnailOrientation={thumbnailOrientation} key={index} data={item} />
            }
})
        }
    </div>
  )
}

export default Homepage