import React from 'react'
const PdfCard = ({data}) => {
    const downloadPdf = () => {
        const pdfUrl = data?.url 
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = `${data?.title}`; // Change the filename as needed
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    }
  return (
    <div className='pdfCard' onClick={downloadPdf}>
        <div className="imageContainer">
            <img src={data?.image} alt="ImageBanner" />
        </div>
        <h1 className="pdfName">{data?.title}</h1>
        <span className="size">{data?.size}</span>
       
    </div>
  )
}

export default PdfCard