import React, { useEffect } from 'react';
import { useSelector } from "react-redux";

const HowToUpdate = () => {
    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const projectInfo = useSelector((state) => state?.projectInfo?.value);

    return (
        <div className="howtoUpdateScreen">
            <div className="uppersection">
                <div className="wrapper">
                    <div className="boxContainer">
                        <h1 className="heading">How to Delete or Update RedeemTV</h1>
                        <p>Welcome to our step-by-step guide on how to update Redeem TV mobile apps on both iOS and Android devices. Keeping your apps up-to-date ensures you have access to the latest features, improvements, and security enhancements. Follow the instructions below to update your apps effortlessly.</p>
                        <h2>iOS (Apple App Store)</h2>
                        <h4>To Delete RedeemTV</h4>
                        <ol>
                            <li>Locate the RedeemTV app you want to delete on your iOS device's home screen.</li>
                            <li>Press and hold the RedeemTV app icon until it starts to shake.</li>
                            <li>An "X" button should appear on the top-left corner of the RedeemTV app icon.</li>
                            <li>Tap the "X" button, and a confirmation message will appear.</li>
                            <li>Confirm by tapping "Delete."</li>
                        </ol>
                        <h4>Install the Latest Version</h4>
                        <ol>
                            <li>Unlock your device and go to the home screen.</li>
                            <li>Find and tap on the "App Store" icon.</li>
                            <li>In the bottom navigation, select "Today."</li>
                            <li>Tap on your profile picture in the upper right corner.</li>
                            <li>Scroll down to the "Purchased" section.</li>
                            <li>Locate RedeemTV in your purchased apps and tap "Download" to reinstall the latest version.</li>
                        </ol>
                        <h2>Android</h2>
                        <h4>Step 1 : Access Google Play Store</h4>
                        <ol>
                            <li>Unlock your Android device and go to the home screen.</li>
                            <li>Find and tap on the "Google Play Store" icon.</li>
                        </ol>
                        <h4>Step 2 : Check for Updates</h4>
                        <ol>
                            <li>Tap the three horizontal lines (hamburger menu) in the top-left corner.</li>
                            <li>Select "My apps & games."</li>
                            <li>Tap the "Updates" tab.</li>
                            <li>Here, you'll see a list of apps with available updates.</li>
                        </ol>
                        <h4>Step 3 : Update an App</h4>
                        <ol>
                            <li>Tap "Update" next to "Redeem TV".</li>
                            <li>Wait for the update to download and install.</li>
                        </ol>
                        <h3>Additional Tips</h3>
                        <ul className="tips">
                            <li> It's a good practice to enable automatic updates in your device settings for hassle-free app updates in the future.</li>
                            <li> Ensure you have a stable internet connection, preferably Wi-Fi, to download updates efficiently.</li>
                            <li> Keep your device charged or connected to a power source to avoid interruptions during the update process.</li>
                        </ul>

                        <p >We hope this guide has been helpful in keeping your Redeem TV app current and ensuring the best user experience. If you encounter any issues or have further questions, please don't hesitate to reach out to our support team.</p>
                        <p className="desc">Enjoy the latest features and improvements in your Redeem TV app!</p>
                    </div>
                </div>
            </div>
            <div className="lowersection">
                <h3>AVAILABLE FOR STREAMING ON :</h3>
                <div className="iconContainer">
                    <div className="container">
                        <div className="appIconContainer" title="Ios" onClick={() => window.open(projectInfo?.projectConfig?.config?.IOS_APP_STORE_URL, "blank")}>
                            <img src={require("../../assets/Images/ios.png")} alt="iOS Logo" />
                        </div>
                    </div>
                    <div className="container ">
                        <div className="appIconContainer androidContainer" title="Android" onClick={() => window.open(projectInfo?.projectConfig?.config?.ANDROID_PLAY_STORE_URL, "blank")}>
                            <img src={require("../../assets/Images/android.png")} alt="Android Logo" />
                        </div>
                    </div>
                    <div className="container">
                        <div className="appIconContainer fire" title="Fire" onClick={() => window.open(projectInfo?.projectConfig?.config?.FIRE_TV_APPSTORE_URL, "blank")}>
                            <img src={require("../../assets/Images/fire_tv.png")} alt="Fire TV Logo" />
                        </div>
                    </div>
                    <div className="container ">
                        <div className="appIconContainer androidTvContainer" title="AndroidTv" onClick={() => window.open(projectInfo?.projectConfig?.config?.ANDROID_TV_PLAY_STORE_URL, "blank")}>
                            <img src={require("../../assets/Images/androidTv.png")} alt="Android TV Logo" />
                        </div>
                    </div>
                    <div className="container">
                        <div className="appIconContainer" title="Apple" onClick={() => window.open(projectInfo?.projectConfig?.config?.APPLE_TV_APP_STORE_URL, "blank")}>
                            <img src={require("../../assets/Images/apple_tv.png")} alt="Apple TV Logo" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowToUpdate;