import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as UserIcon } from "../../../assets/Icons/user_avatar.svg";
import { ReactComponent as FacebookIcon } from "../../../assets/Icons/facebook.svg";
import { ReactComponent as GoogleIcon } from "../../../assets/Icons/googleIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as service from "./service";
import { getUser } from "../../../Redux/UserSlice/UserSlice";
import { analytics2, authenticate2 } from "../../../network/service";
import { getAccessToken } from "../../../Redux/AToken/ATokenSlice";
import { getUserDetails } from "../../../Redux/UserDetailsSlice/UserDetailsSlice";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import LoginModal from "../../../Components/Modals/LoginModal/LoginModal";
const Login = () => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [locationState, setLocationState] = useState();
  const [emailLoginModal,setEmailLoginModal] = useState(false)
  const [isShowPassword,setIsShowPassword] = useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.accessToken?.value);
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const appInfo = {
    accessToken: accessToken,
    projectDetails: projectInfo,
  };
  const [redirectUrl, setRedirectUrl] = useState("");
  const location = useLocation();
  const [googleUser, setGoogleUser] = useState();

  const facebookRef = useRef(null);
  const state = location?.state;
  const tvCode = state?.code;
  useEffect(() => {
    window.scroll(0, 0);
    if (location?.state) {
      setLocationState(location?.state);
    }
    if (location?.state?.path) {
      setRedirectUrl(location?.state?.path);
    } 
      else{
        setRedirectUrl("/home");

      }
  }, [location?.state ]);

  useEffect(() => {
    if (googleUser) {
      getgoogleUserData();
    }
  }, [googleUser]);

  const getgoogleUserData = async () => {
    const response = await service.googleLogin(googleUser?.access_token);
    if (response?.status === 200) {
      const values = {
        googleId: response?.data?.id,
        loginType: "google",
        firstName: response?.data?.name,
        email: response?.data?.email,
      };
      loginWithoutCode(values);
    }
  };

  const loginWithoutCode = async (values) => {
    const response = await service.updateLoginWithoutCode(appInfo, values);
    if (response?.status === 200) {
      localStorage.setItem("userId", response?.data?.data[0]?.user_id);
      tokenAuthenticate();
      updateDeviceAnalytics(
        response?.data?.data[0]?.user_id,
        appInfo,
        response?.data?.data[0]?.user_email,
        response?.data?.data[0]?.first_name
      );
      setTimeout(() => {
        dispatch(
          getUser({
            user: response?.data?.data[0]?.user_id,
          })
        );
      }, 1000);
      navigate(redirectUrl, { state: locationState });
    }
  };
  const googleLoginHandler = useGoogleLogin({
    onSuccess: (codeResponse) => setGoogleUser(codeResponse),
    onError: (error) => {},
  });

  const responseFacebook = (response) => {
    if (response?.userID) {
      const values = {
        facebookId: response?.userID,
        loginType: "facebook",
        firstName: response?.name,
        email: response?.email,
      };
      loginWithoutCode(values);
    }
    // You can handle the Facebook login response here
  };
  const handleUpdate = (item) => {
    const { name, value } = item?.target;
    handleChange(name, value);
  };
  const handleChange = (name, value) => {
    setValues({
      ...values,
      [name]: value,
    });
  };
  const validateLogin = () => {
    let error = {};
    let validateStatus = true;
    const { email, password } = values;
    // let emailRegex = /^\w+([\.\+\-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
    if (!email) {
      error.email = "Email is required!";
      validateStatus = false;
    } else if (!email?.match(emailRegex)) {
      error.email = "Enter a valid email!";
      validateStatus = false;
    }
    if (!password) {
      error.password = "Password is required!";
      validateStatus = false;
    }
    // else if (!password?.match(passwordRegex)) {
    //   error.password =
    //     "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special characters!";
    //   validateStatus = false;
    // }
    setErrors(error);
    return validateStatus;
  };
  const updateDeviceAnalytics = async (userId, appInfo, userEmail, userName) => {
    await analytics2(userId, appInfo, userEmail, userName);
  };

  const tokenAuthenticate = async () => {
    const response = await authenticate2(appInfo);
    if (response?.status === 200) {
      dispatch(
        getAccessToken({
          accessToken: response?.data?.token,
        })
      );
      dispatch(
        getUserDetails({
          userDetails: response?.data,
        })
      );
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const validateStatus = validateLogin();
      if (validateStatus) {
        const loginResponse = await service.login(values, appInfo);
        if (loginResponse?.status === 200) {
          localStorage.setItem("userId", loginResponse?.data?.data[0]?.user_id);
          tokenAuthenticate();
          updateDeviceAnalytics(
            loginResponse?.data?.data[0]?.user_id,
            appInfo,
            loginResponse?.data?.data[0]?.user_email,
            loginResponse?.data?.data[0]?.first_name
          );
          // setTimeout(() => {
            dispatch(
              getUser({
                user: loginResponse?.data?.data[0]?.user_id,
              })
            );
          // }, 1000);
          navigate(redirectUrl, { state: locationState ,replace:true});
        } else {
          toast.error(loginResponse?.data?.message, {
            position: "top-center",
          });
        }
      } else {
        return false;
      }
    } catch (err) {
      toast.error(err?.loginResponse?.data?.message, {
        position: "top-center",
      });
    }
  };

  return (
    <div className="loginPage">
      <ToastContainer />
      {
        emailLoginModal&&
        <LoginModal isEmailForm={true} setEmailLoginModal={setEmailLoginModal} tvCode={tvCode}/>
      }
      {projectInfo?.projectConfig?.pubid === 50040 && (
        <div className="updatesContainer">
          <p>
            As we have upgraded our platforms, users who have registered on or before 08/05/2023 have to reset their
            password for login to Bounty Hunter D.
          </p>
        </div>
      )}
      <div className="loginContainer">
        <div className="top">
          <div className="userIconContainer">
            <UserIcon />
          </div>
          <h1>Welcome Back!</h1>
          {projectInfo?.projectConfig?.config?.SOCIAL_LOGIN_REQUIRED === "true" && (
            <>
              {projectInfo?.projectConfig?.config?.FACEBOOK_LOGIN_REQUIRED === "true" && (
                <FacebookLogin
                  appId={projectInfo?.projectConfig?.config?.FACEBOOK_APP_ID}
                  autoLoad={false}
                  fields="name,email,picture"
                  callback={responseFacebook}
                  ref={facebookRef}
                  render={(renderProps) => (
                    <div className="facebookContainer" onClick={renderProps.onClick}>
                      <div className="background"></div>
                      <div className="contents">
                        <div className="left">
                          <FacebookIcon />
                        </div>
                        <div className="right">
                          <span>Log in via Facebook</span>
                        </div>
                      </div>
                    </div>
                  )}
                  onFailure={(res) => {}}
                />
              )}

              {projectInfo?.projectConfig?.config?.GOOGLE_LOGIN_REQUIRED === "true" &&
                projectInfo?.projectConfig?.config?.GOOGLE_CLIENT_ID && (
                  <div
                    className="googleContainer"
                    onClick={() => {
                      googleLoginHandler();
                    }}
                  >
                    <div className="background"></div>
                    <div className="contents">
                      <div className="left">
                        <GoogleIcon />
                      </div>
                      <div className="right">
                        <span>Log in via Google</span>
                      </div>
                    </div>
                  </div>
                )}
            </>
          )}
          <div
            className="buttonContainer"
            onClick={() => {
              setEmailLoginModal(true);
            }}
          >
            <div className="background"></div>
            <button>Sign in Via Email</button>
          </div>
          <div className="seperate">
            <span className="line"></span>
            <span>OR</span>
            <span className="line"></span>
          </div>
        </div>

        <div className="bottom">
          <h3>Log in via Email & Password</h3>
          <form action="post">
            <div className="emailContainer">
              <input
                type="email"
                placeholder="Email"
                name="email"
                onChange={(item) => {
                  handleUpdate(item);
                }}
              />
            </div>{" "}
            {errors?.email && <span className="error">{errors?.email}</span>}
            <div className="passwordContainer">
              <input
                type={isShowPassword?"text":"password"}
                placeholder="Password"
                name="password"
                onChange={(item) => {
                  handleUpdate(item);
                }}
              />
              <span className="passwordController" title={isShowPassword?"Hide password":"Show password"} onClick={()=>setIsShowPassword(!isShowPassword)}>
                {
                  isShowPassword?(
                    <i class="fa-solid fa-eye-slash"></i>
                  ):(

                    <i class="fa-solid fa-eye"></i>
                  )
                }
              </span>
            </div>{" "}
            {errors?.password && <span className="error">{errors?.password}</span>}
            <div
              className="buttonContainer"
              onClick={(event) => {
                handleSubmit(event);
              }}
            >
              <div className="background"></div>
              <button>Log in</button>
            </div>
            <h4 className="forgotPassword" onClick={() => navigate("/forgot-password")}>
              Forgot Password?
            </h4>
            <h4>
              Don't have an account?{" "}
              <span
                onClick={() =>
                  navigate("/register", {
                    state: { path: location?.state?.path },
                  })
                }
              >
                Sign up
              </span>
            </h4>
          </form>
          <p>
            By registering, you agree to {projectInfo?.projectConfig?.config?.TAB_TITLE}'s <br />{" "}
            <span onClick={() => navigate("/terms-and-conditions")}>Terms of Use</span> and{" "}
            <span onClick={() => navigate("/privacy-policy")}>Privacy Policy</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
