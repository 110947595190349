import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate,useLocation } from "react-router-dom";
import { ReactComponent as PlayIcon } from "../../assets/Icons/play.svg";
import { ReactComponent as CrossIcon } from "../../assets/Icons/cross.svg";
import { updateContinueWatching, updateWatchlistData } from "../../network/service";
import { fetchWatchlistShows } from "../../Screens/MyList/service";
import { getMyListData } from "../../Redux/MyList/MyListSlice";
import { imageUrlCheck } from "../../utils/utils";
import { getPodcastModal } from "../../Redux/PodcastModal/podcastModalSlice";
import { toast } from "react-toastify";
import { getRemovedContinueWatchingData } from "../../Redux/RemoveContinueWatching/RemoveContinueWatchingSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LoadingBanner from "../../Screens/homepage/Components/LoadingBanner";
import { convertTimeToLocal } from "../../utils/utils";
const ShowCard = ({ data, b2b, metaData, imageUrl, type, playVideo,minHeight }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const removeContinueWatchingData = useSelector((state) => state?.removeContinueWatching?.value);
  const thumbnailOrientation = projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION;
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const userSubscriptionData = useSelector((state) => state?.userSubscription?.value);
  const [watchlistStatus, setWatchlistStatus] = useState();
  const [imagURL, setImageURL] = useState(
    thumbnailOrientation === "PORTRAIT"
      ? require("../../assets/Images/loading-portrait.gif")
      : require("../../assets/Images/loading-landscape.gif")
  );
  const [isImageLoad, setIsImageLoad] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  const location = useLocation();
  useEffect(() => {
    afterImageLoad();
    if (data?.watchlist_flag === 1) {
      setWatchlistStatus("added");
    } else {
      setWatchlistStatus("removed");
    }
  }, [data]);

  const fetchWatchlist = async () => {
    const response = await fetchWatchlistShows(appInfo);
    if (response?.status === 200) {
      dispatch(
        getMyListData({
          myList: response?.data?.data,
        })
      );
    }
  };

  const updateWatchlist = async (showId, flag) => {
    try {
      const response = await updateWatchlistData(appInfo, showId, flag);

      if (response?.status === 200) {
        fetchWatchlist();
        if (flag === 1) {
          // toast.success("Added to mylist", {
          //   position: "bottom-center",
          // });
          setWatchlistStatus("added");
        } else {
          // toast.success("Removed from mylist", {
          //   position: "bottom-center",
          // });
          setWatchlistStatus("removed");
        }
      }
    } catch (err) {}
  };

  const addToWatchlist = (showId) => {
    if (user) {
      updateWatchlist(showId, 1);
    } else {
      navigate("/login");
    }
  };

  const removeFromWatchlist = (showId) => {
    updateWatchlist(showId, 0);
  };

  const subscriptionCheck = (userSub, videoSub) => {
    const hasMatch = userSub?.some((user) => videoSub?.some((video) => user.sub_id === video.subscription_id));
    return hasMatch;
  };

  const showHandleClick = () => {
    if (data?.type === "LIVE_EVENT") {
      navigate(`/event/${data?.vanity_url ? data?.vanity_url : data?.event_id}`);
    } else if (data?.type === "PODCAST") {
      let podcastData = {
        isOpen: true,
        data,
      };
      dispatch(
        getPodcastModal({
          podcastModal: podcastData,
        })
      );
    } else if (data?.type === "NEWS") {
      navigate("/news");
    } else if (data?.type === "CONTINUE_WATCHING") {
      navigate(`/videos/${data?.video_vanity_url}`,{ state: { data:data} });
      // navigate(`/show-details/${data?.vanity_url}`, {
      //   state: { showId: data?.show_id,type:data?.type,videoId:data?.video_id},
      // })
    } else if (data?.type === "UPCOMING_EVENT") {
      navigate(`/event/${data?.vanity_url ? data?.vanity_url : data?.event_id}`);
    } 
    else if(type ==="Episode"){
      navigate(`/videos/${data?.video_vanity_url?data?.video_vanity_url:data?.vanity_url}`,{
        state: { data:location?.state?.data,
          showDetails : location?.state?.showDetails? location?.state?.showDetails:data},
      });
    }
    else if (type !== "episodes"){
      if (data?.video_id) {
        navigate(`/videos/${data?.video_vanity_url?data?.video_vanity_url:data?.vanity_url}`,{
          state: {showDetails : location?.state?.showDetails? location?.state?.showDetails:data},
        });
      } else {
        navigate(`/show-details/${data?.vanity_url}`, {
          state: { showId: data?.show_id, type: data?.type },
        });
      }
    } 
     else if (type === "episodes") {
      if (b2b) {
        playVideo(data, "episode");
      } else {
        navigate(`/videos/${data?.vanity_url}`,{state:{showVanityUrl:data?.show_vanity_url}});
      }
    }
  };

  const replaceImage = (error) => {
    error.target.src = projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE;
  };

  const afterImageLoad = (e) => {
    setIsImageLoad(true);
    let image;
    if (
      imageUrlCheck(
        thumbnailOrientation === "PORTRAIT"
          ? data?.logo || data?.thumbnail
          : data?.logo_thumb || data?.thumbnail_350_200
      ) === true
    ) {
      if (thumbnailOrientation === "PORTRAIT") {
        image = data?.logo || data?.thumbnail;
      } else {
        image = data?.logo_thumb || data?.thumbnail_350_200;
      }
    } else {
      if (thumbnailOrientation === "PORTRAIT") {
        image = process.env.REACT_APP_IMAGE_URL + (data?.logo || data?.thumbnail);
      } else {
        image = process.env.REACT_APP_IMAGE_URL + (data?.logo_thumb || data?.thumbnail_350_200);
      }
    }
    setImageURL(image);
  };

  const removeContinueWatchingHandler = () => {
    updateContinueWatchingData();
  };

  const updateContinueWatchingData = async () => {
    const res = await updateContinueWatching(appInfo, data?.video_id);
    console.log(res);
    if (res?.status === 200) {
      dispatch(
        getRemovedContinueWatchingData({
          removeContinueWatching: [...removeContinueWatchingData, data?.video_id],
        })
      );
      toast.success(res?.data?.message, {
        position: "bottom-center",
      });
    }
  };

  return (
    <div className={"showCardContainer showContents"} title={data?.show_name || data?.video_title}>
      {/* <ToastContainer /> */}
      {data?.type === "CONTINUE_WATCHING" && (
        <div
          className="continueWatchingRemove"
          title="Remove from continue watching"
          onClick={() => removeContinueWatchingHandler()}
        >
          <CrossIcon />
        </div>
      )}
 {
        b2b ? null :
      <div className="premiumStatus">
        { (data?.is_free_video === false || data?.free_video === false) && userSubscriptionData?.data?.length > 0 ? (
          <div className="free">
            <img
              src={require("../../assets/Images/unlock.png")}
              alt="FreeIcon"
            />
          </div>
        ) : (data?.is_free_video === false || data?.free_video === false)&&
            ( <div className="free">
            <img
              src={require("../../assets/Images/lock.png")}
              alt="FreeIcon"
            />
          </div>)}
      </div>
      }

      <div className={isImageLoad ? `imageContainer imageLoaded` : `imageContainer ${minHeight === "none"?"noMinHeight":"minHeight"}`}>
        {
          !isImageLoad&&
        <div className="loading">
          <LoadingBanner minHeight="400px"/>
        </div>
        }
        {imageUrlCheck(thumbnailOrientation==='PORTRAIT'?(data?.logo || data?.thumbnail):(data?.logo_thumb || data?.thumbnail_350_200)) ===false ? (
          <img
            src={thumbnailOrientation==='PORTRAIT'?`${process.env.REACT_APP_IMAGE_URL}${
              (data?.logo || data?.thumbnail)
            }`:`${process.env.REACT_APP_IMAGE_URL}${
              (data?.logo_thumb || data?.thumbnail_350_200)
            }`}
            alt="ShowImage"
          />
        ) : (
          <img src={thumbnailOrientation==='PORTRAIT'?
          `${data?.logo || data?.thumbnail}`
          :
          `${data?.logo_thumb || data?.thumbnail_350_200}`
        } alt="ShowImage" />
          
        )}
        {/* <img
            className={!isImageLoad?(thumbnailOrientation==="PORTRAIT"?"portrait":"landscape"):"showImage"}
              src={
                imagURL
              }
              onError={(e)=>replaceImage(e)}
              onLoad={(e)=>afterImageLoad(e)} 
              alt="ShowImage"
            /> */}
        {/* {imageUrlCheck(
          thumbnailOrientation === "PORTRAIT"
            ? data?.logo || data?.thumbnail
            : data?.logo_thumb || data?.thumbnail_350_200
        ) ? (
          // <img src={thumbnailOrientation === "PORTRAIT"?(data?.logo || data?.thumbnail):(data?.logo_thumb || data?.thumbnail_350_200)} alt="" />
          <LazyLoadImage
            src={
              thumbnailOrientation === "PORTRAIT"
                ? data?.logo || data?.thumbnail
                : data?.logo_thumb || data?.thumbnail_350_200
            }
            effect="blur"
            alt="Image Alt"
            placeholderSrc={"https://miro.medium.com/max/882/1*9EBHIOzhE1XfMYoKz1JcsQ.gif"}
            onLoad={(e) => afterImageLoad()}
          />
        ) : (
          // <img src={thumbnailOrientation === "PORTRAIT"?(process.env.REACT_APP_IMAGE_URL + (data?.logo || data?.thumbnail)):(process.env.REACT_APP_IMAGE_URL + (data?.logo_thumb || data?.thumbnail_350_200))} alt="" />
          <LazyLoadImage
            src={
              thumbnailOrientation === "PORTRAIT"
                ? process.env.REACT_APP_IMAGE_URL + (data?.logo || data?.thumbnail)
                : process.env.REACT_APP_IMAGE_URL + (data?.logo_thumb || data?.thumbnail_350_200)
            }
            effect="blur"
            alt="Image Alt"
            placeholderSrc={require("../../assets/Images/Gizmott_Logo.png")}
            onLoad={(e) => afterImageLoad()}
         
            />
        )} */}
        {/* <LazyLoadImage src={imagURL} effect="blur"  alt="Image Alt"  placeholderSrc={"https://miro.medium.com/max/882/1*9EBHIOzhE1XfMYoKz1JcsQ.gif"} /> */}
        {data?.type === "CONTINUE_WATCHING" && (
          <div className="continueWatchingBar">
            <div
              className="line"
              style={{ width: data?.watched_percentage <= 1 ? 1 + "%" : data?.watched_percentage + "%" }}
            ></div>
          </div>
        )}

        <div className="extraActions">
          <div className="showCardOverlay" onClick={showHandleClick}></div>
          <div className="playIconContainer" onClick={showHandleClick}>
            <PlayIcon />
          </div>
          {metaData && data?.show_id && (
            <>
              {watchlistStatus === "added" ? (
                <div className="addToList" onClick={() => removeFromWatchlist(data?.show_id)}>
                  <span>Remove from My List</span>
                </div>
              ) : (
                <div className="addToList" onClick={() => addToWatchlist(data?.show_id)}>
                  <span>Add to My List</span>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className="metaData">
        <h1 className="title">{data?.show_name || data?.video_title}</h1>
        {data?.start_time && 
        <h2  className="time"> 
                {convertTimeToLocal(data?.start_time )
                  .toString()
                  .toLowerCase()}
        </h2>
         }
        {metaData ? (
          <>
            <div className="datas">
              <div className="left">
                {data?.year && <span className="year">{data?.year}</span>}
                {data?.duration_text && (
                  <span className={`duration ${data?.year && "showDot"}`}>{data?.duration_text}</span>
                )}
              </div>
              <div className="right">{data?.rating && <span className="certificate">{data?.rating}</span>}</div>
            </div>
            <div className="genres">
              {/* {
            data?.category_names?.map((item,index)=>(
              <span key={index}>{item}</span>
            ))
          } */}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ShowCard;
