import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../Loading/Loading";
import ShowCard from "../ShowCard/ShowCard";
import * as service from "./service";
import { useWindowWidth } from "@react-hook/window-size";
import { imageUrlCheck, rowItemCount } from "../../utils/utils";
import useScrollPosition from "../ScrollPosition/useScrollPosition";
import { ReactComponent as FacebookIcon } from "../../assets/Icons/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/Icons/twitter.svg";
import { FacebookShareButton, TwitterShareButton,EmailShareButton } from "react-share";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import MagazineCard from "../MagazineCard/MagazineCard";

const CommonPage = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const thumbnailOrientation = projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION;
  const removeContinueWatchingData = useSelector((state) => state?.removeContinueWatching?.value);

  const [shows, setShows] = useState([]);
  const [img, setImg] = useState(true);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [rowItemsClass,setRowItemsClass] = useState("")
  const [sliceCount,setSliceCount] = useState(20)
  const [pageURL, setPageURL] = useState(0);
  const [isShareActive, setIsShareActive] = useState(false);

  const {careers} = useParams();
  const type = location?.state?.type;
  const navigate = useNavigate();
  const windowSize = useWindowWidth()
  const containerRef = useRef(null)
  const scrollPosition = useScrollPosition();

  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };

  useEffect(() => {
    setPageURL(window.location.href);
    // if (menuItem) {
    fetchCategoryDetails();
    // } else {
    //   fetchCategoryDetails();
    // }
  }, [location,removeContinueWatchingData]);

  useEffect(()=>{
    if(!loading && sliceCount<shows?.shows?.length){
      lazyLoad()
    }
  },[loading,scrollPosition,shows])

  useEffect(() => {
    if(windowSize>980){
      if(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT){
        setRowItemsClass(rowItemCount(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT))
      }else{
        setRowItemsClass("rowCount4")
      }
    }else{
      setRowItemsClass("")
    }
  }, [projectInfo,windowSize]);

  const  fetchCategoryDetails= async () => {
    setLoading(true)
    try {
      const itemResponse = await service.getAllShowsOfCategory(appInfo, careers);
      if (itemResponse?.status === 200) {
        setShows(itemResponse?.data?.data);
        setImg(false);
        setLoading(false)
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "top-center",
      });
      setLoading(false)

    }
  };
  // const fetchCategoryDetails = async () => {
  //   setLoading(true)
  //   try {
  //     const categoryResponse = await service.getAllShowsOfCategory(
  //       appInfo,
  //       location?.state
  //     );
  //     if (categoryResponse?.status === 200) {
  //       setShows(categoryResponse?.data?.data);
  //       setImg(true);
  //       setLoading(false)
  //     }
  //   } catch (err) {
  //     setLoading(false)
  //     toast.error(err?.response?.data?.message, {
  //       position: "top-center",
  //     });
  //   }
  // };

  const lazyLoad = () => {
    const containerHeight = containerRef?.current?.clientHeight;
    if(scrollPosition + 2200 >= containerHeight){
      setSliceCount(sliceCount+20)
    }
  }
  if(loading){
    return(
      <Loading/>
    )
  }

  const resetValues = (e) => {
    setIsShareActive(false)
  }

  const shareHandler = (e) => {
    e.stopPropagation()
    setIsShareActive(!isShareActive)
  }
  return (
    <>
   
      <div className="commonPage" ref={containerRef} onClick={()=>resetValues()}>
      <ToastContainer />

      {shows?.shows?.length  ===0 | Object.entries(shows).length === 0  ? 
  
        <h1
               style={{
                 color: "#E72A31",
                 display:"flex",
                 justifyContent:'center',
             
               }}
             >
               No data found
             </h1>

    
 
     :
      <div className="wrapper">
        
        {
        shows?.banner ? <div className="bgImageContainer">
       <div className="top">
        <div className="left">
          <h1 className="heading">{shows?.category_name}</h1>
          <p className="desc">{shows?.synopsis?.slice(0,250)}{shows?.synopsis?.length>250&&"..."}</p>
          <div className="buttonContainer" onClick={(e)=>shareHandler(e)}>
          <div className="background" ></div>
          <button>
            <span className="icon"><i class="fa-solid fa-share"></i></span>
            Share
            </button>
            <div className={isShareActive ? "active shareContainer" : "shareContainer"} >
                    <div className="facebookContainer socialContainer">
                      {/* <div className="left"> */}
                      <FacebookShareButton url={pageURL} quote={"Share"} className="facebook">
                        Facebook
                        <div className="icon">
                          <FacebookIcon />
                        </div>
                      </FacebookShareButton>
                      {/* </div> */}
                      {/* <div className="right">
                        <FacebookIcon />
                      </div> */}
                    </div>
                    <div className="twitterContainer socialContainer" onClick={() => setIsShareActive(false)}>
                      <TwitterShareButton url={pageURL} className="twitter">
                        Twitter
                        <div className="icon">
                          <TwitterIcon />
                        </div>
                      </TwitterShareButton>
                    </div>
                    <div className="socialContainer" onClick={() => setIsShareActive(false)}>
                      <EmailShareButton url={pageURL} >
                        Email
                        <div className="icon">
                        <i class="fa-solid fa-envelope"></i>
                        </div>
                      </EmailShareButton>
                    </div>
                    <div className="socialContainer" onClick={() => setIsShareActive(false)}>

                      <CopyToClipboard text={pageURL}>
                        <button onClick={()=>alert("Copied Successfully!")}>
                        Copy URL
                        <div className="icon">
                        <i class="fa-solid fa-copy"></i>
                        </div>
                        </button>
                      </CopyToClipboard>
                      </div>
                  </div>
        </div>
        </div>
        <div className="right">
        <div className="smallBanner">
        {
          imageUrlCheck(shows?.banner)?(
            <img
            src={shows?.banner}
            alt="BgImage"
          />
          ):(
            <img
              src={`${process.env.REACT_APP_IMAGE_URL}${shows?.banner}`}
              alt="BgImage"
            />

          )
        }
        </div>
        </div>
        </div>
        <div className="gradientBanner">
        {
          imageUrlCheck(shows?.banner)?(
            <img
            src={shows?.banner}
            alt="BgImage"
          />
          ):(
            <img
              src={`${process.env.REACT_APP_IMAGE_URL}${shows?.banner}`}
              alt="BgImage"
            />

          )
        }
        </div>
      
                 
          <div className="bottomGradient" />   
          
        </div>
        :
        <div className="top">
        <h1 className="heading">{shows?.category_name}</h1>
        <p className="desc">{shows?.synopsis}</p>
        
      </div>
        
      }
        
        
        <div className="bottom">
          <div className="itemsContainer">
              

            {shows?.shows?.slice(0,sliceCount)?.map((item, index) => (
              <div
              className={shows?.category_name !== 'Magazines' ?
                (thumbnailOrientation === 'PORTRAIT' ? `items portrait ${rowItemsClass}` : `items landscape ${rowItemsClass}`) : `magazineCard ${rowItemsClass}` 
              }>
            {
                    shows?.category_name!=="Magazines" ?
                     <ShowCard data={item}  key={index}  imageUrl={img} season={false} metaData={true}/> 
                     :
                     <MagazineCard data={item}  key={index}  imageUrl={img} season={false} metaData={true}  className="magazineCard"/>
                  }
              </div>
            ))}
          </div>
        </div>
      </div>
         
         }
        </div>  
    </>

  );
};

export default CommonPage;
