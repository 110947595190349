import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loading from "../../Components/Loading/Loading";
import ShowCard from "../../Components/ShowCard/ShowCard";
import { ToastContainer, toast } from "react-toastify";
import { useWindowWidth } from "@react-hook/window-size";

import * as service from "./service";
import { rowItemCount } from "../../utils/utils";
const MyList = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const myListRedux = useSelector((state) => state?.myList?.value);
  const [shows, setShows] = useState([]);
  const [error, setError] = useState();
  const [loading , setLoading] =useState(true)
  const [thumbnailOrientation, setThumbnailOrientation] = useState("");
  const [rowItemsClass,setRowItemsClass] = useState("")

  const location = useLocation();
  const navigate = useNavigate();
  const windowSize = useWindowWidth()

  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };

  useEffect(() => {
    if (
      projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT"
    ) {
      setThumbnailOrientation("PORTRAIT");
    } else {
      setThumbnailOrientation("LANDSCAPE");
    }
  }, [projectInfo]);

  useEffect(() => {
    if(windowSize>980){
      if(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT){
        setRowItemsClass(rowItemCount(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT))
      }else{
        setRowItemsClass("rowCount4")
      }
    }else{
      setRowItemsClass("")
    }
  }, [projectInfo,windowSize]);

  useEffect(() => {
    fetchWatchlistShows();
  }, []);
  useEffect(() => {
    if (myListRedux) {
      setShows(myListRedux);
    }
  }, [myListRedux]);
  const fetchWatchlistShows = async () => {
    try{
      
      const response = await service.fetchWatchlistShows(appInfo);
      if (response?.status === 200) {
        setShows(response?.data?.data);
        setLoading(false)
      }
    }catch(err){
        setLoading(false)
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
  };

  if(loading){
    return(
      <Loading/>
    )
  }
  return (
    <div className="myList">
      <ToastContainer />

      <div className="wrapper containerHeight">
        <div className="top">
          <h1 className="heading">My List</h1>
        </div>
        <div className="bottom">
          <div className="itemsContainer">
            {shows?.map((item, index) => (
              <div className={thumbnailOrientation===`PORTRAIT`?`items portrait ${rowItemsClass}`:`items landscape ${rowItemsClass}`}>
              <ShowCard
                data={item}
                key={index}
                imageUrl={true}
                season={false}
                metaData={true}
                modalNavigation={true}
              />
            </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyList;
