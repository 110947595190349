import React, { useEffect, useState } from "react";
import { ReactComponent as UserIcon } from "../../../assets/Icons/user_avatar.svg";
import { ReactComponent as FacebookIcon } from "../../../assets/Icons/facebook.svg";
import { ReactComponent as Arrow } from "../../../assets/Icons/filledArrow.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUserDetails } from "../../../Redux/UserDetailsSlice/UserDetailsSlice";
import { ReactComponent as GoogleIcon } from "../../../assets/Icons/googleIcon.svg";

import * as service from "./service";
import { analytics2, authenticate2 } from "../../../network/service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUser } from "../../../Redux/UserSlice/UserSlice";
import OtpModal from "../../../Components/Modals/OtpModal";
import { getAccessToken } from "../../../Redux/AToken/ATokenSlice";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { googleLogin, updateLoginWithoutCode } from "../Login/service";
import { getLanguageModal } from "../../../Redux/LanguageModal/languageModalSlice";

const Register = () => {
  const accessToken = useSelector((state) => state.accessToken?.value);
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const user = useSelector((state) => state?.user?.value);
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [otpModal, setOtpModal] = useState(false);
  const [userRegisterId, setUserRegisterId] = useState();
  const [redirectUrl, setRedirectUrl] = useState("");
  const [isShowPassword, setIsShowPassword] = useState("");
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [googleUser, setGoogleUser] = useState();

  const appInfo = {
    accessToken: accessToken,
    projectDetails: projectInfo,
  };
  useEffect(() => {
    window.scroll(0, 0);
    if (location?.state?.path) {
      setRedirectUrl(location?.state?.path);
    } else {
      setRedirectUrl("/home");
    }
  }, [location?.state]);

  useEffect(() => {
    if (googleUser) {
      getgoogleUserData()
    }
  }, [googleUser]);

  const getgoogleUserData = async () => {
    const response = await googleLogin(googleUser?.access_token)
    if(response?.status === 200) {
      const values={
        googleId:response?.data?.id,
        loginType:"google",
        firstName:response?.data?.name,
        email:response?.data?.email
      }
      loginWithoutCode(values)
    }
  }

  const loginWithoutCode = async (values) =>{
    const response =  await updateLoginWithoutCode(appInfo,values)
    if(response?.status === 200){
      localStorage.setItem("userId", response?.data?.data[0]?.user_id);
      tokenAuthenticate();
      updateDeviceAnalytics(
        response?.data?.data[0]?.user_id,
        appInfo,
        response?.data?.data[0]?.user_email,
        response?.data?.data[0]?.first_name
      );
      dispatch(
        getUser({
          user: response?.data?.data[0]?.user_id,
        })
      );
      dispatch(getLanguageModal({
        languageModal:{
          isOpen:true,
          selectedLanguage:null
        }
      }))
      navigate("/language-selection", { state:{previousState:location?.state,redirectUrl}  });
    }
  }


  const googleLoginHandler = useGoogleLogin({
    onSuccess: (codeResponse) => setGoogleUser(codeResponse),
    onError: (error) => {},
  });


  const handleUpdate = (item) => {
    const { name, value } = item?.target;
    handleChange(name, value);
  };
  const handleChange = (name, value) => {
    setValues({
      ...values,
      [name]: value,
    });
  };
  const validateRegister = () => {
    let error = {};
    let validateStatus = true;
    const {
      email,
      password,
      confirmPassword,
      firstName,
      lastName,
      gender,
      dob,
    } = values;
    let nameRegex = /([A-z])+(.?[a-zA-Z])*('?[a-zA-Z])*/;
    // let emailRegex = /^\w+([\.\+\-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // let passwordRegex =
    //   /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
    let passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])[a-zA-Z0-9!@#$%^&*_]{8,20}$/;

    if (!email) {
      error.email = "Email is required!";
      validateStatus = false;
    } else if (!email?.match(emailRegex)) {
      error.email = "Enter a valid email!";
      validateStatus = false;
    }
    if (!password) {
      error.password = "Password is required!";
      validateStatus = false;
    } 
    // else if (!password?.match(passwordRegex)) {
    //   error.password =
    //     "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special characters!";
    //   validateStatus = false;
    // }

    if (password !== confirmPassword) {
      error.confirmPassword = "Password does not match!";
      validateStatus = false;
    }
    if (!firstName) {
      error.firstName = "First name is required!";
      validateStatus = false;
    } else if (!firstName.match(nameRegex)) {
      error.firstName = "Name must contains only alphabets!";
      validateStatus = false;
    }
    if (!lastName) {
      error.lastName = "Last name is required!";
      validateStatus = false;
    } else if (!lastName.match(nameRegex)) {
      error.lastName = "Name must contains only alphabets!";
      validateStatus = false;
    }
    // if (!dob) {
    //   error.dob = "Please enter date of birth";
    //   validateStatus = false;
    // }
    // if (!gender) {
    //   error.gender = "Select a gender!";
    //   validateStatus = false;
    // }
    setErrors(error);
    return validateStatus;
  };
  const updateDeviceAnalytics = async (userId, appInfo, userEmail,userName) => {
    await analytics2(userId, appInfo, userEmail,userName);
  };
  const tokenAuthenticate = async () => {
    const response = await authenticate2(appInfo);
    if (response?.status === 200) {
      dispatch(
        getAccessToken({
          accessToken: response?.data?.token,
        })
      );
      dispatch(
        getUserDetails({
          userDetails: response?.data,
        })
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const validateStatus = validateRegister();
      if (validateStatus) {
        const response = await service.registerUser(appInfo, values);
        if (response?.status === 200) {
          localStorage.setItem("userId",response?.data?.data[0]?.user_id)
          if(values?.referralCode){
            localStorage.setItem("referralCode",values?.referralCode)
          }
          toast.success(response?.data?.message, {
            position: "top-center",
          });
          setUserRegisterId(response?.data?.data[0]?.user_id);
          tokenAuthenticate();
          updateDeviceAnalytics(
            response?.data?.data[0]?.user_id,
            appInfo,
            values?.email,
            response?.data?.data[0]?.first_name
          );
          dispatch(
            getUser({
              user: response?.data?.data[0]?.user_id,
            })
          );
          localStorage.setItem("redirectURL",redirectUrl)
          navigate("/language-selection", {state:{redirectURL:redirectUrl}, replace: true });
        } else if (
          response?.status === 201 &&
          appInfo?.projectDetails?.projectConfig?.config
            ?.REGISTRATION_OTP_REQUIRED === "true"
        ) {
          toast.success(response?.data?.message, {
            position: "top-center",
          });
          setOtpModal(true);
          setUserRegisterId(response?.data?.user_id);
        }
        else{
          toast.error(response?.data?.message, {
            position: "top-center",
          });    
        }
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "top-center",
      });
    }
  };

  return (
    <div className="registerPage">
      {otpModal && (
        <OtpModal
          setOtpModal={setOtpModal}
          appInfo={appInfo}
          userRegisterId={userRegisterId}
          updateDeviceAnalytics={updateDeviceAnalytics}
          tokenAuthenticate={tokenAuthenticate}
          values={values}
          urlToRedirect={redirectUrl}
        />
      )}
      <ToastContainer />
      <div className="registerContainer">
        <div className="top">
          <div className="userIconContainer">
            <UserIcon />
          </div>
          <h1>Let's get you set up!</h1>
          <h6>It's free. No subscription required</h6>
          {
            projectInfo?.projectConfig?.config?.SOCIAL_LOGIN_REQUIRED==="true"&&
            <>
            {
              projectInfo?.projectConfig?.config?.FACEBOOK_LOGIN_REQUIRED==="true"&&
              <div className="facebookContainer">
                <div className="background"></div>
                <div className="contents">
                  <div className="left">
                    <FacebookIcon />
                  </div>
                  <div className="right">
                    <span>Log in via Facebook</span>
                  </div>
                </div>
              </div>
            }

            {
              projectInfo?.projectConfig?.config?.GOOGLE_LOGIN_REQUIRED==="true"&&
              projectInfo?.projectConfig?.config?.GOOGLE_CLIENT_ID&&
              <div
                className="googleContainer"
                onClick={() => {
                  googleLoginHandler();
                }}
              >
                <div className="background"></div>
                <div className="contents">
                  <div className="left">
                    <GoogleIcon />
                  </div>
                  <div className="right">
                    <span>Log in via Google</span>
                  </div>
                </div>
              </div>

            }
            
            
          <div className="seperate">
            <span className="line"></span>
            <span>OR</span>
            <span className="line"></span>
          </div>
            </>
          }
        </div>
        <div className="bottom">
          <h3>Sign up via Email</h3>
          <form action="post">
            <div className="oneByOne">
              <input
                type="text"
                placeholder="First Name"
                name="firstName"
                onChange={(item) => {
                  handleUpdate(item);
                }}
              />
            </div>{" "}
            {errors.firstName && (
              <span className="error">{errors.firstName}</span>
            )}
            <div className="oneByOne">
              <input
                type="text"
                placeholder="Last Name"
                name="lastName"
                onChange={(item) => {
                  handleUpdate(item);
                }}
              />
            </div>
            {errors.lastName && (
              <span className="error">{errors.lastName}</span>
            )}
            <div className="oneByOne">
              <input
                type="email"
                placeholder="Email"
                name="email"
                onChange={(item) => {
                  handleUpdate(item);
                }}
              />
            </div>{" "}
            {errors.email && <span className="error">{errors.email}</span>}
            <div className="oneByOne">
              <input
                type={isShowPassword?"text":"password"}
                placeholder="Password"
                name="password"
                onChange={(item) => {
                  handleUpdate(item);
                }}
              />
              <span className="passwordController" title={isShowPassword?"Hide password":"Show password"} onClick={()=>setIsShowPassword(!isShowPassword)}>
                {
                  isShowPassword?(
                    <i class="fa-solid fa-eye-slash"></i>
                  ):(
                    <i class="fa-solid fa-eye"></i>
                  )
                }
              </span>
            </div>{" "}
            {errors.password && (
              <span className="error">{errors.password}</span>
            )}
            <div className="oneByOne">
              <input
                type={isShowConfirmPassword?"text":"password"}
                placeholder="Confirm Password"
                name="confirmPassword"
                onChange={(item) => {
                  handleUpdate(item);
                }}
              />
              <span className="passwordController" title={isShowConfirmPassword?"Hide password":"Show password"} onClick={()=>setIsShowConfirmPassword(!isShowConfirmPassword)}>
                {
                  isShowConfirmPassword?(
                    <i class="fa-solid fa-eye-slash"></i>
                  ):(
                    <i class="fa-solid fa-eye"></i>
                  )
                }
              </span>
            </div>
            {errors.confirmPassword && (
              <span className="error">{errors.confirmPassword}</span>
            )}
            <div className="oneByOne">
              <input
                type="text"
                placeholder="Referral Code (Optional)"
                name="referralCode"
                onChange={(item) => {
                  handleUpdate(item);
                }}
              />
            </div>
            {/* <div className="oneByTwo">
              <div className="containers">
                <div className="left">
                  <label htmlFor="dob">Birthday</label>

                  <input
                    type="date"
                    name="dob"
                    onChange={(item) => {
                      handleUpdate(item);
                    }}
                  />
                  {errors.dob && <span className="error">{errors.dob}</span>}
                </div>
                <div className="right">
                  <label htmlFor="gender">Gender</label>
                  <div className="genderContainer">
                    <Arrow />
                    <select
                      name="gender"
                      id="gender"
                      onChange={(item) => {
                        handleUpdate(item);
                      }}
                    >
                      <option value="Select">Select</option>
                      <option value="male">Male</option>
                      <option value="Female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>{" "}
                  {errors.gender && (
                    <span className="error">{errors.gender}</span>
                  )}
                </div>
              </div>
            </div> */}
            {/* {errors.message && (
                    <span className="error">{errors.message}</span>
                  )} */}
            <div
              className="buttonContainer"
              onClick={(event) => {
                handleSubmit(event);
              }}
            >
              <div className="background"></div>
              <button>Sign up</button>
            </div>
          </form>
          <p>
            By registering, you agree to {projectInfo?.projectConfig?.config?.TAB_TITLE}'s <br /> <span onClick={()=>navigate("/terms-and-conditions")}>Terms of Use</span>{" "}
            and <span onClick={()=>navigate("/privacy-policy")}>Privacy Policy</span>
          </p>
          <h4 className="signIn">
            Already have an account?{" "}
            <span onClick={() => navigate("/login")}>Log in</span>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Register;
