import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loading from "../../Components/Loading/Loading";
import * as service from "./service"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PlaylistCard from "../../Components/PlaylistCard/PlaylistCard";
import useScrollPosition from "../../Components/ScrollPosition/useScrollPosition";
import { useRef } from "react";
import { rowItemCount } from "../../utils/utils";
import { useWindowWidth } from "@react-hook/window-size";
import {imageUrlCheck} from "../../utils/utils";
const Playlist =({video,type,playVideo,downloadPlaylist,b2bId}) =>{
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const thumbnailOrientation = projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION;

  const [img, setImg] = useState(true);
  const [loading , setLoading] = useState(true);
  const [rowItemsClass,setRowItemsClass] = useState("")
  const [videos , setVideos] = useState([]);
  const [sliceCount ,setSliceCount] =useState(20)
  const [playContent ,setPlayContent] =useState(false)
  
   
  
  const windowSize = useWindowWidth()
  const scrollPosition = useScrollPosition();
  const containerRef = useRef(null);
  const {id} = useParams()
 
 
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
    
  };

  useEffect(()=>{
    window.scroll(0,0)
    
    setLoading(true)
  },[])


 

  useEffect(()=>{
    if(type==="B2bPlaylist"){
      setVideos(video);
      setLoading(false);
      setImg(false);
    }else{
      fetchPlaylistDetails();
    }
    
  },[]);

  useEffect(()=>{
    if(!loading && sliceCount<videos?.videos?.length){
      lazyLoad()
    }
  },[loading,scrollPosition,videos])

  useEffect(() => {
    if(windowSize>980){
      if(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT){
        setRowItemsClass(rowItemCount(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT))
      }else{
        setRowItemsClass("rowCount4")
      }
    }else{
      setRowItemsClass("")
    }
  }, [projectInfo,windowSize]);

  const fetchPlaylistDetails=async () => {
    setLoading(true)
    try{
        const playlistResponse = await service.getAllVideosOfPlayList(appInfo,id);
        
        if(playlistResponse?.status === 200){
            setVideos(playlistResponse?.data?.data);
            setLoading(false);
            setImg(false);
        }

    }
    catch(err){
        toast.error(err?.response?.data?.message,{
          position:"top-center",  
        });
        setLoading(false)

    }
  }
  const lazyLoad = () => {
    const containerHeight = containerRef?.current?.clientHeight;
    if(scrollPosition + 2200 >= containerHeight){
      setSliceCount(sliceCount+20)
    }
  }

  if(loading){
    return(
        <Loading/>
    )
  }



    return(
        <>
        <div className="playListPage" ref={containerRef}> 
        {videos?.length  ===0 | Object.entries(videos).length === 0  ? 
  
        <h1
              style={{
                color: "#E72A31",
                display:"flex",
                justifyContent:'center',
            
              }}
            >
              No data found
            </h1>
            
:
            <div className="wrapper">
          
                 {
                  videos?.thumbnail ? <div className="backgroundContainer">
                <div className={playContent ? "headingSection-wrap":"headingSection"}>
                    <h1 className="heading">{videos.name}</h1>
                    <p className="desc">{videos.description}</p>
                  </div>
                  {imageUrlCheck(videos?.thumbnail) ? (
                          <img
                            src={`${videos?.thumbnail}`}
                            alt="Background"
                          />
                        ) : (
                          <img
                            src={
                              `${process.env.REACT_APP_IMAGE_URL}${videos?.thumbnail_350_200}`
                            }
                            alt="Background"
                          />
                        )
                        }
                  <div className="bottomGradient" />   
                </div>
                :
                <div className="headingSection">
                <h1 className="heading">{videos.name}</h1>
                <p className="desc">{videos.description}</p>
              </div>    
              }
                <div className="playlistBody">
                    <div className="playlistContainer">
                        {videos?.videos?.slice(0,sliceCount)?.map((item,index)=>( 
                                 <div className={thumbnailOrientation===`PORTRAIT`?`playlistItems portrait ${rowItemsClass}`:`playlistItems landscape ${rowItemsClass}`}>
                                    <PlaylistCard
                                        data={item}
                                        key={index}
                                        imageUrl={img}
                                        season={false}
                                        metaData={true}
                                        type={type}
                                        playVideo={playVideo}
                                      setPlayContent={b2bId ? setPlayContent : null}
                                    />
                                 </div>
                                 
                        ))        
                        } 
            
                    </div>
                </div>
             
            </div>
        }  
        </div>
        </>
    )
}
export default Playlist;
