import { createSlice } from "@reduxjs/toolkit";

export const RemoveContinueWatchingSlice=createSlice({
    name:"removeContinueWatching",
    initialState:{
        value:[]
    },
    reducers:{
        getRemovedContinueWatchingData:(state,action)=>{
            state.value=action?.payload?.removeContinueWatching
        }
    }
})

export const {getRemovedContinueWatchingData}=RemoveContinueWatchingSlice.actions;
export default RemoveContinueWatchingSlice.reducer