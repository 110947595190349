import { fetchApiData, updateApiData } from "../../../utils/utils";

export const stripeDecode = async (appInfo, sessionId) => {
  const pubId = appInfo?.projectDetails?.projectConfig?.pubid;
  const countryCode = appInfo?.projectDetails?.geoInfo?.countryCode;
  const params = {
    pubid: pubId,
    sessionId: sessionId,
    country_code: countryCode,
  };
  const api = "subscription/stripe/details";
  return await fetchApiData(appInfo, api, params);
};

export const updatePayment = async (
  appInfo,
  subscription,
  modeOfPayment,
  status,
  isUpgrade
) => {
  const couponId = localStorage.getItem("couponId");
  const orginalAmount = localStorage.getItem("selectedAmount");
  const deviceType = localStorage.getItem("deviceType");
  const selectedSubId = localStorage.getItem("selectedSubId");
  const previousSubId = localStorage.getItem("previousSubId");
  const userId = localStorage.getItem("userId");
  const ideabizPhone = localStorage.getItem("ideabizPhone")

  let transactionType = 1;
  if (isUpgrade === "true") {
    transactionType = 6;
  }

  const values = {
    device_id: appInfo?.projectDetails?.device_id,
    transaction_type: transactionType,
    subscription_id: selectedSubId,
    mode_of_payment: modeOfPayment,
    status: status,
    uid: userId,
    amount: orginalAmount,
    receiptid: subscription,
    pubid: appInfo?.projectDetails?.projectConfig?.pubid,
    device_type: deviceType,
    ideabiz_phone:ideabizPhone
  };
  if (couponId) {
    values.coupon_id = couponId;
  }
  if (isUpgrade === "true") {
    values.previous_subscription_id = previousSubId;
  }

  const location = {
    path: "updatePayment",
    values: values,
  };

  const api =
    isUpgrade === "true"
      ? "subscription/upgrade"
      : "subscription/updateTransaction";
  return await updateApiData(appInfo, api, location);
};

export const paypalSubscription = async (appInfo, subId) => {
  const params = {
    sub_id: subId,
  };
  const api = "subscription/paypal/verify";
  return await fetchApiData(appInfo, api, params);
};
