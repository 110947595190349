import React, { Suspense, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay,Navigation } from "swiper";

import "swiper/css/effect-fade";
import "swiper/css/autoplay";
import 'swiper/css/navigation';
import { imageUrlCheck } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import LoadingBanner from "./LoadingBanner";
import SwiperItem from "./SwiperItem";
import Loading from "../../../Components/Loading/Loading";
import { useSelector } from "react-redux";

const FeaturedBanner2 = ({ data }) => {
    const navigate = useNavigate()
    const [loading,setLoading] = useState(true)
    const isBannerLoaded = useSelector((state)=>state?.bannerLoading?.value)
    useEffect(()=>{
        if(!data){
          setLoading(true)
        }else{
          setLoading(false)
        }
       
    },[data])
    const showClickHandler = (item) => {
        if(item?.show_id){
          navigate(`/show-details/${item?.vanity_url?item?.vanity_url:item?.show_id}`, {
            state: { showId: item?.show_id },
          })
        }else if(item?.event_id){
          navigate(`/event/${item?.vanity_url?item?.vanity_url:item?.event_id}`, {
            state: { showId: item?.event_id },
          })
        }
      }
  return (
    <div className="featuredBannerType2">
      {
        !isBannerLoaded&&
      <div className="loading">
        <LoadingBanner minHeight={"600px"}/>
      </div>
      }
      <Swiper
        slidesPerView={1}
        watchSlidesProgress
        effect={"fade"}
        spaceBetween={0}
        loop={true}
        navigation ={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        className="mySwiper"
        modules={[EffectFade, Autoplay,Navigation]}
        // allowSlideNext={false}
        // allowSlidePrev={false}
      >
        {data?.shows?.map((item, index) => (
          <SwiperSlide key={index}>
              <SwiperItem data={item}/>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default FeaturedBanner2;
