import React, { useEffect, useState ,Fragment } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PlayIcon } from "../../assets/Icons/play.svg";
import { imageUrlCheck } from "../../utils/utils";
const PlaylistCard = ({ data, metaData, imageUrl,type,playVideo ,b2bId,setPlayContent }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const thumbnailOrientation = projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION;
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const [imagURL,setImageURL] = useState(thumbnailOrientation==="PORTRAIT"?require("../../assets/Images/loading-portrait.gif"):require("../../assets/Images/loading-landscape.gif"))
  const [isImageLoad,setIsImageLoad] = useState(false)
  const navigate = useNavigate();
  
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  useEffect(() => {
   afterImageLoad()

  }, [data]);

  const showHandleClick = () => {
    
         if(type === "B2bPlaylist"){
playVideo(data,"B2bPlaylist")
setPlayContent(true)
         }else{
          navigate(`/videos/${data?.vanity_url}`)
         }
        
         
  }

  const replaceImage=(error)=>{
    error.target.src= projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE
}

  const afterImageLoad=(e)=>{
    setIsImageLoad(true)
    let image;
    if(imageUrlCheck(thumbnailOrientation==='PORTRAIT'? data?.thumbnail: data?.thumbnail_350_200)===true){
      if(thumbnailOrientation === "PORTRAIT"){
        image = data?.thumbnail
      }
      else{
        image = data?.thumbnail_350_200

      }
    }else {
      if(thumbnailOrientation === "PORTRAIT"){
        image = process.env.REACT_APP_IMAGE_URL + ( data?.thumbnail)
      }else{
        image = process.env.REACT_APP_IMAGE_URL + (data?.thumbnail_350_200)
      }
    }
    setImageURL(image)
  }

  return (
    <div className={"playlistCardContainer showContents"} title={data?.show_name || data?.video_title}>
     <div className="imageContainer">
          <img
            className={!isImageLoad?(thumbnailOrientation==="PORTRAIT"?"portrait":"landscape"):"showImage"}
              src={
                imagURL
              }
              onError={(e)=>replaceImage(e)}
              onLoad={(e)=>afterImageLoad(e)} 
              alt="ShowImage"
            />
        {
          data?.type === "CONTINUE_WATCHING" &&
        <div className="continueWatchingBar">
          <div className="line" style={{width:data?.watched_percentage <=1?1+"%":data?.watched_percentage+"%"}}></div>
        </div>
        }

        <div className="extraActions">
          <div
            className="showCardOverlay"
           onClick={showHandleClick}
          ></div>
          <div
            className="playIconContainer"
            onClick={showHandleClick}
          >
            <PlayIcon />
          </div>
        </div>
      </div>

      <div className="metaData">
        <h1 className="title">{data?.show_name || data?.video_title}</h1>
          {metaData ? (
            <>
              <div className="datas">
                <div className="left">
                  {
                    data?.year&&
                  <span className="year">{data?.year}</span>
                  }
                  {
                    data?.duration &&
                  <span className={`duration ${data?.year }`}>{data?.duration}</span>
                  }
                    {
                    data?.video_description&&
                    <p className={`description`}>{data?.video_description}</p>
                  }
                </div>
                <div className="right">
                  {
                    data?.rating&&
                  <span className="certificate">{data?.rating}</span>
                  }
                </div>
              </div>
              <div className="genres">
              </div>
            </>
          ) : (
          null
          )}
      </div>
    </div>
  );
};

export default PlaylistCard;
