import { createSlice } from "@reduxjs/toolkit";

export const bannerLoadingSlice=createSlice({
    name:"bannerLoading",
    initialState:{
        value:false
    },
    reducers:{
        getBannerLoading:(state,action)=>{
            state.value=action?.payload?.bannerLoading
        }
    }
})

export const {getBannerLoading}=bannerLoadingSlice.actions
export default bannerLoadingSlice.reducer