import { fetchApiDataV2 } from "../../utils/utils";

export const getShows = async (appInfo, key,type) => {
  const api = "show/search";
  const params = {
    key,
    type
  };
  return await fetchApiDataV2(appInfo, api, params);
};
