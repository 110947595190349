import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { applyCouponCode, getIdeaBizToken, stripeSession } from "./service";
import { ToastContainer, toast } from "react-toastify";
import {
  PayPalScriptProvider,
} from "@paypal/react-paypal-js";
import PaypalButtonWrapper from "./PaypalButtonWrapper";
import IdeaBizPhoneModal from "../../Components/Modals/IdeaBizPhoneModal";
const Payment = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const androidData = useSelector((state) => state?.deviceSubscription?.value)
  const user = useSelector((state) => state?.user?.value);
  const [selectedSubscriptionData, setSelectedSubscriptionData] = useState({});
  const [couponCode, setCouponCode] = useState();
  const [error, setError] = useState();
  const [CouponSuccess, setCouponSuccess] = useState(false);
  const [paypalLink,setPaypalLink] = useState("")
  const [paypalSub,setPaypalSub] = useState(false)
  const [ideaBizSubscribeModal,setIdeaBizSubscribeModal] = useState(false)
  const [ideaBizToken,setIdeaBizToken] = useState("")
  const location = useLocation();
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  
  useEffect(()=>{
    if(selectedSubscriptionData){
    localStorage.setItem("selectedSubId", selectedSubscriptionData?.subscription_id);
    localStorage.setItem("deviceType", androidData?.isAndroid?"android-web":"web");

        const user_data = {
            transaction_type: 1,
            subscription_id: selectedSubscriptionData?.subscription_id,
            uid: user,
            amount: selectedSubscriptionData?.price,
            pubid: projectInfo?.projectConfig?.pubid,
            country_code: projectInfo?.countryCode,
            device_type: androidData?.isAndroid?"android-web":"web",
          };
          const user_details = encodeURIComponent(JSON.stringify(user_data));
          const link = `https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=TL7WSPLLZZ4WS&custom=${user_details}&discount=10&currency_code=USD&upload=1`
          setPaypalLink(link)
        }
    
  },[selectedSubscriptionData])
  useEffect(() => {
    window.scrollTo(0, 0);
    if (location?.state?.subscription) {
      setSelectedSubscriptionData(location?.state?.subscription);
    }
  }, [location?.state]);

  const couponCodeApi = async () => {
    try {
      const couponCodeResponse = await applyCouponCode(
        appInfo,
        couponCode?.couponCode,
        selectedSubscriptionData?.subscription_id
      );
      if (couponCodeResponse?.status === 200) {
        setError(null)
        setCouponSuccess(true);
      localStorage.setItem("couponId",couponCodeResponse?.data?.data?.coupon_id);
        
        setCouponCode({
          ...couponCode,
          couponId: couponCodeResponse?.data?.data?.coupon_id,
        });
      } else {
        setError(couponCodeResponse?.data?.message);
      }
    } catch (err) {}
  };


  const stripeApi = async () => {
    if (CouponSuccess) {
      const paymentInfo = {
        subId: selectedSubscriptionData?.subscription_id,
        couponId: couponCode?.couponId,
      };
      try {
        const stripeResponse = await stripeSession(appInfo, paymentInfo);
        if (stripeResponse?.status === 200) {
          window.open(stripeResponse?.data?.url,"_self")
         
        }
      } catch (err) {
        toast.error(err?.response?.data?.message, {
          position: "bottom-center",
        });
      }
    } else {
      const paymentInfo = {
        subId: selectedSubscriptionData?.subscription_id,
      };
      try {
        const stripeResponse = await stripeSession(appInfo, paymentInfo);
        if (stripeResponse?.status === 200) {
          window.open(stripeResponse?.data?.url,"_self")
        }
      } catch (err) {
        toast.error(err?.response?.data?.message, {
          position: "bottom-center",
        });
      }
    }
  };

  const applyCouponCodeHandler = () => {
    if (!couponCode) {
      setError("Enter a coupon code!");
    } else {
      couponCodeApi();
    }
  };

  const stripePaymentHandler = () => {
    localStorage.setItem("selectedAmount", selectedSubscriptionData?.price);

    stripeApi();
  };

  const ideaBizPaymentHandler = () => {
    fetchIdeaBizToken()
  }

  const fetchIdeaBizToken = async () => {
    try{
      const response = await getIdeaBizToken(appInfo)
      if(response?.status===200){
          setIdeaBizSubscribeModal(true)
          setIdeaBizToken(response?.data?.access_token)
      }

    }catch(err){

    }

}

  return (
    <div className="paymentScreen">
      {
        ideaBizSubscribeModal&&
      <IdeaBizPhoneModal setIdeaBizSubscribeModal={setIdeaBizSubscribeModal} selectedSubscriptionData={selectedSubscriptionData} ideabizToken={ideaBizToken}/>
      }
      <div className="wrapper">
        <h1 className="heading">Payment Gateway</h1>
        <div className="mainContainer">
          <div className="left">
            {
              selectedSubscriptionData?.is_coupon === false &&
            (<div className="couponCodeContainer">
              <label htmlFor="couponCode">Coupon Code:</label>
              <div className="inputContainer">
                <input
                  name="couponCode"
                  type="text"
                  id="couponCode"
                  value={couponCode?.couponCode || ""}
                  onChange={(e) =>
                    setCouponCode({couponCode:e.target.value})
                  }
                />
                <div
                  className="buttonContainer"
                  onClick={() => applyCouponCodeHandler()}
                >
                  <div className="background" />
                  <button>Apply</button>
                </div>
              </div>
              {error && <span className="error">{error}</span>}
              {CouponSuccess && <span className="success">Coupon applied</span>}
            </div>)
            }
            <div className="paymentGatewayContainer">
              <h2 className="heading">Select a payment gateway</h2>
              <ul className="gateways">
                <li onClick={stripePaymentHandler}>
                  <div className="imageContainer">
                    <img
                      src={require("../../assets/Images/mastercard.png")}
                      alt="MasterCard"
                    />
                  </div>
                </li>
                <li onClick={stripePaymentHandler}>
                  <div className="imageContainer">
                    <img
                      src={require("../../assets/Images/visa.png")}
                      alt="Visa"
                    />
                  </div>
                </li>
                <li onClick={stripePaymentHandler}>
                  <div className="imageContainer">
                    <img
                      src={require("../../assets/Images/americanExpress.png")}
                      alt="AmericanExpress"
                    />
                  </div>
                </li>
                {
                  selectedSubscriptionData?.ideabiz_keyword&&
                <li onClick={ideaBizPaymentHandler}>
                  <div className="imageContainer">
                    <img
                      src={require("../../assets/Images/ideabiz.png")}
                      alt="AmericanExpress"
                    />
                  </div>
                </li>
                }
                
              </ul>
            </div>
            
          </div>
            {
              projectInfo?.projectConfig?.config?.PAYPAL_CLIENT_ID&&

          <div className="right">
                <PayPalScriptProvider 
                  options={{
                      "client-id":projectInfo?.projectConfig?.config?.PAYPAL_CLIENT_ID ,
                        components: "buttons",
                        currency: selectedSubscriptionData?.currency,
                        vault:true,
                        intent: selectedSubscriptionData?.subscription_type_id===3 || selectedSubscriptionData?.subscription_type_id===4? "subscription" :null,
                  }}
                >
                  <PaypalButtonWrapper subscriptionData={selectedSubscriptionData} />

                </PayPalScriptProvider>
          </div>
            }
        </div>
      </div>
    </div>
  );
};

export default Payment;
