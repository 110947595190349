import React from 'react'
import { Line } from 'rc-progress';
function ProgressBar({downloadB2bModal}) {
  return (
    <div className="progressBar">
   <p className='downloadText'>Downloading-{downloadB2bModal.filename}...{`${Math.ceil(downloadB2bModal?.downloadProgress)}%`}</p>
   <Line percent={Math.ceil(downloadB2bModal?.downloadProgress)} strokeWidth={1} strokeColor="#4BB543" />   
    </div>
  )
}

export default ProgressBar
