import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoutes = () => {
  const user = useSelector((state) => state?.user?.value);
  const userDetails = useSelector((state) => state?.userDetails?.value);
  const urlParams = new URLSearchParams(window.location.search);
  const androidToken = urlParams.get("antkn");
  const tvCode = urlParams.get("code");
  const path = window.location.pathname;
  const queryString = window.location.search;
 
  const fullPath = path + queryString;
  var searchParams = new URLSearchParams(queryString);
  var code = searchParams.get("code");
  
  if((userDetails?.language_id ===null || !userDetails?.language_id || userDetails?.language_id === "")&& !androidToken  && !tvCode){
    return <Navigate to="/language-selection" />
  }else{
      if(user){
          return(
              <Outlet/>
          )
      }else
      {
          return <Navigate to="/login"
            state={{
           path: fullPath,
           code
           }} />
      }
  }
 
}

export default ProtectedRoutes