import { initializeApp } from "firebase/app";
import {getDatabase} from "firebase/database" 
const initFirebase = () => {
    const firebaseConfig = {
        apiKey: "AIzaSyBE97LWxZ9Uwf8NUzjaZUyOEgYDs5xLs9w",
        authDomain: "redeem-tv.firebaseapp.com",
        databaseURL: "https://redeem-tv-default-rtdb.firebaseio.com",
        projectId: "redeem-tv",
        storageBucket: "redeem-tv.appspot.com",
        messagingSenderId: "871365444482",
        appId: "1:871365444482:web:c9ecdaaa7b1d4c10b2059b",
        measurementId: "G-CPRKG8RGZC"
      };
      const app = initializeApp(firebaseConfig);
      return getDatabase(app);

}

export default initFirebase;