import { fetchApiData } from "../../utils/utils"

export const getLiveInfo = async (appInfo)=>{
    const api=`fastchannel/${appInfo?.projectDetails?.projectConfig?.config?.FAST_CHANNEL_ID}`
    return await fetchApiData(appInfo,api)
  }

  export const getEventInfo = async (appInfo,eventId)=>{
    const api=`event/${eventId}`
    return await fetchApiData(appInfo,api)
  }
