import React from "react";
import { ReactComponent as FacebookIcon } from "../../assets/Icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "../../assets/Icons/instagram.svg";
import { ReactComponent as TwitterIcon } from "../../assets/Icons/twitter.svg";
import { ReactComponent as LinkedInIcon } from "../../assets/Icons/linkedIn.svg";
import { ReactComponent as AppStore } from "../../assets/Icons/appstore.svg";
import { ReactComponent as PlayStore } from "../../assets/Icons/playstore.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const navigate = useNavigate();
  const linkHandler = (data) => {
    if (data?.seo_url) {
      navigate(data?.seo_url);
    } else if (data?.link) {
      window.open(data?.link);
    }
  };
  return (
    <div className="footerContainer">
      <div className="wrapper">
        <div className="topContainer">
          <div className="left">
            <div className="logoContainer">
              <img src={projectInfo?.projectConfig?.config?.LOGO} alt="Logo" />
            </div>
          </div>
          <div className="right">
            <ul className="social">
              {projectInfo?.projectConfig?.config?.FACEBOOK && (
                <li
                  onClick={() =>
                    window.location.href = projectInfo?.projectConfig?.config?.FACEBOOK
                    // window.open(
                    //   projectInfo?.projectConfig?.config?.FACEBOOK,
                    //   "blank"
                    // )
                  }
                >
                  <FacebookIcon />
                </li>
              )}

              {projectInfo?.projectConfig?.config?.INSTAGRAM && (
                <li
                  onClick={() =>
                    window.location.href = projectInfo?.projectConfig?.config?.INSTAGRAM
                    // window.open(
                    //   projectInfo?.projectConfig?.config?.INSTAGRAM,
                    //   "blank"
                    // )
                  }
                >
                  <InstagramIcon />
                </li>
              )}
              {projectInfo?.projectConfig?.config?.TWITTER && (
                <li
                  onClick={() =>
                    window.location.href = projectInfo?.projectConfig?.config?.TWITTER
                    // window.open(
                    //   projectInfo?.projectConfig?.config?.TWITTER,
                    //   "blank"
                    // )
                  }
                >
                  <TwitterIcon />
                </li>
              )}
              {projectInfo?.projectConfig?.config?.LINKEDIN && (
                <li
                  onClick={() =>
                    window.location.href = projectInfo?.projectConfig?.config?.LINKEDIN
                    // window.open(
                    //   projectInfo?.projectConfig?.config?.LINKEDIN,
                    //   "blank"
                    // )
                  }
                >
                  <LinkedInIcon />
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="centerContainer">
          <div className="informationContainer">
            {
              projectInfo?.projectConfig?.config?.CUSTOM_FOOTERS?.length > 0 &&
              <div className="items">
                <h6 className="heading">COMPANY</h6>
                <ul>
                  {projectInfo?.projectConfig?.config?.CUSTOM_FOOTERS?.map((data, index) => (
                    <li>
                      <span onClick={() => linkHandler(data)}>{data?.title}</span>
                    </li>
                  ))}

                  {/* <li>Careers</li> */}
                  {/* <li onClick={()=>navigate("/contact-us")}>Contact</li> */}
                </ul>
              </div>
            }
            <div className="items">
              <h6 className="heading">SUPPORT</h6>
              <ul>
                <li>
                  <span onClick={() => navigate("/contact-support")}>
                    Contact Support
                  </span>
                </li>
                <li>
                  <span onClick={() => navigate("/how-to-update")}>
                    How to Upgrade
                  </span>
                </li>
              </ul>
            </div>
            <div className="items">
              <h6 className="heading">GET THE APPS</h6>
              <ul>
                {projectInfo?.projectConfig?.config?.IOS_APP_STORE_URL && (
                  <li>
                    <span
                      onClick={() =>
                        window.open(
                          projectInfo?.projectConfig?.config?.IOS_APP_STORE_URL,
                          "blank"
                        )
                      }
                    >
                      iOS
                    </span>
                  </li>
                )}
                {projectInfo?.projectConfig?.config?.ANDROID_PLAY_STORE_URL && (
                  <li>
                    <span
                      onClick={() =>
                        window.open(
                          projectInfo?.projectConfig?.config
                            ?.ANDROID_PLAY_STORE_URL,
                          "blank"
                        )
                      }
                    >
                      Android
                    </span>
                  </li>
                )}
                {projectInfo?.projectConfig?.config?.ROKU_CHANNEL_STORE_URL && (
                  <li>
                    <span
                      onClick={() =>
                        window.open(
                          projectInfo?.projectConfig?.config
                            ?.ROKU_CHANNEL_STORE_URL,
                          "blank"
                        )
                      }
                    >
                      Roku
                    </span>
                  </li>
                )}
                {projectInfo?.projectConfig?.config?.FIRE_TV_APPSTORE_URL && (
                  <li>
                    <span
                      onClick={() =>
                        window.open(
                          projectInfo?.projectConfig?.config
                            ?.FIRE_TV_APPSTORE_URL,
                          "blank"
                        )
                      }
                    >
                      Amazon Fire
                    </span>
                  </li>
                )}
                {projectInfo?.projectConfig?.config
                  ?.ANDROID_TV_PLAY_STORE_URL && (
                    <li>
                      <span
                        onClick={() =>
                          window.open(
                            projectInfo?.projectConfig?.config
                              ?.ANDROID_TV_PLAY_STORE_URL,
                            "blank"
                          )
                        }
                      >
                        Android TV
                      </span>
                    </li>
                  )}
                {projectInfo?.projectConfig?.config?.APPLE_TV_APP_STORE_URL && (
                  <li>
                    <span
                      onClick={() =>
                        window.open(
                          projectInfo?.projectConfig?.config
                            ?.APPLE_TV_APP_STORE_URL,
                          "blank"
                        )
                      }
                    >
                      Apple TV
                    </span>
                  </li>
                )}
              </ul>
            </div>
            <div className="items">
              <h6 className="heading">LEGAL</h6>
              <ul>
                <li>
                  <span onClick={() => navigate("/privacy-policy")}>
                    Privacy Policy
                  </span>
                </li>
                <li>
                  <span onClick={() => navigate("/terms-and-conditions")}>
                    Terms of Use
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="bottomContainer">
        <div className="stores">
          {projectInfo?.projectConfig?.config?.IOS_APP_STORE_URL && (
            <AppStore
              onClick={() =>
                window.open(
                  projectInfo?.projectConfig?.config?.IOS_APP_STORE_URL,
                  "blank"
                )
              }
            />
          )}
          {projectInfo?.projectConfig?.config?.ANDROID_PLAY_STORE_URL && (
            <PlayStore
              onClick={() =>
                window.open(
                  projectInfo?.projectConfig?.config?.ANDROID_PLAY_STORE_URL,
                  "blank"
                )
              }
            />
          )}
        </div>
        <p>{projectInfo?.projectConfig?.config?.COPYRIGHT_TEXT}</p>
        <p>All Rights Reserved.</p>
        {
          projectInfo?.projectConfig?.config?.FOOTER_PAYMENTS_REQUIRED === "true" &&
          <div className="typeOfPayments">
            <i className="fa fa-2x fa-cc-discover"></i>
            <i class="fa-brands fa-2x fa-cc-mastercard"></i>
            <i className="fa fa-2x fa-cc-paypal"></i>
            <i className="fa fa-2x fa-cc-visa"></i>
            <i class="fa-regular fa-globe"></i>
          </div>
        }
      </div>
    </div>
  );
};

export default Footer;
