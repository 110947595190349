import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as RightArrow } from "../../../assets/Icons/rightArrow.svg";
import ShowCard from "../../../Components/ShowCard/ShowCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MagazineCard from "../../../Components/MagazineCard/MagazineCard";

const Categories = ({ data, thumbnailOrientation }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const removeContinueWatchingData = useSelector((state) => state?.removeContinueWatching?.value);
  const [categoriesData, setCategoriesData] = useState([]);
  const [hideContinueWatch, setHideContinueWatch] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      setCategoriesData(data?.shows);
      if (data?.type === "CONTINUE_WATCHING") {
        let filteredData = data?.shows?.filter((item) => !removeContinueWatchingData.includes(item?.video_id));
        if (filteredData.length < 1) {
          setHideContinueWatch(true)
        } else {
          setHideContinueWatch(false)
        }
        setCategoriesData(filteredData);
      }
    }
  }, [removeContinueWatchingData, data]);
  return (
    <div className="categoriesContainer" style={{ display: hideContinueWatch ? 'none' : 'block' }}>
      <div className="wrapper">
        <div
          className="categoryNameContainer"
          onClick={() =>
            navigate(`/category/${data?.key}`, {
              state: { careers: data?.key, type: data?.type },
            })
          }
        >
          <h1>{data?.category_name}</h1>
          <RightArrow />
        </div>
        <div className="itemsContainer">
          <Swiper
            modules={[Navigation]}
            spaceBetween={data?.type !== "MAGAZINES" ? 10 : -20}
            slidesPerView={6}
            navigation={true}
            watchSlidesProgress
            loop={false}
            className={data?.type !== "MAGAZINES" ? (thumbnailOrientation === "PORTRAIT" ? "portrait" : "landscape"):"portrait"}
            breakpoints={{
              320: {
                slidesPerView: thumbnailOrientation === "PORTRAIT" ? 2 : 1,
                spaceBetween: 5,
              },
              480: {
                slidesPerView: thumbnailOrientation === "PORTRAIT" ? 3 : 2,
                spaceBetween: 5,
              },
              768: {
                slidesPerView: thumbnailOrientation === "PORTRAIT" ? 4 : 2,
                spaceBetween: 10,
              },
              980: {
                slidesPerView: projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT
                  ? projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT
                  : 4,
                spaceBetween: 10,
              },
            }}
          >
            {categoriesData?.slice(0, 20)?.map((item, index) => (
              <SwiperSlide key={index}
               className= {data?.type === "MAGAZINES" ? "magazine" :"" }
               >
                {index === 19 && ( <div className="showAll" onClick={() => navigate(`/category/${data?.key}`, { state: { careers: data?.key, type: data?.type },
                    })
                  }>
                    <h1>Show All ({categoriesData?.length})</h1>
                  </div>
                )}
                <div className={index === 19 ? "show lastChild" : data?.type !== "MAGAZINES" ?"show":"magazinecard"}>

                  {
                    data?.type !== "MAGAZINES" ?
                      <ShowCard data={item} season={false} metaData={true} type={data?.type} />
                      :
                      <MagazineCard data={item} season={false} metaData={true} type={data?.type} />
                  }

                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Categories;
