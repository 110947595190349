import React from 'react'
import { useNavigate } from 'react-router-dom'

const PlayNextEpisode = ({episodes,findIndex,watchVideoHandler,setNextEpisodeModal}) => {
    let episodeCount= 0
    const navigate = useNavigate()
    const playNextEpisodeHandler = () =>{
        navigate(`/videos/${episodes[0]?.vanity_url}`)
        setNextEpisodeModal(false)
    }
  
    return (
    <div className="playNextEpisode">
      <div className="overlayModal"></div>
      <div className="contents">
        <h1>Do you want to play next episode?</h1>
        <div className="buttons">
            <div className="buttonContainer"  onClick={()=>playNextEpisodeHandler()} >
            <div className="background"></div>
            <button>Yes</button>
            </div>

            <div className="buttonContainer" onClick={()=>setNextEpisodeModal(false)}>
            <div className="background"></div>
            <button  >No</button>
            </div>

        </div>
      </div>
    </div>
  )
}

export default PlayNextEpisode