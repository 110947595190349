import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import VideoJS from "../../Components/VideoJsPlayer/VideoJS";
import { convertAdUrl, getEventInfo, imageUrlCheck, onVideoPlayFunction } from "../../utils/utils";
import { getLiveInfo, getVideoDetails, getRedirectDetails } from "./service";
import { ReactComponent as BackButton } from "../../assets/Icons/backButton.svg";
import { ReactComponent as LeftArrow } from "../../assets/Icons/leftArrow.svg";
import WatchWithoutAdsModal from "../../Components/Modals/WatchWithoutAdsModal";
import ContinueWatchingModel from "../../Components/Modals/ContinueWatchingModel";
import { getPlayerToken, updateVideoWatchlistData, getUserSubscription, updateWatchlistData } from "../../network/service";
import { getVideoSubscription } from "../Subscription/service";
import { getUserSubscriptionData } from "../../Redux/UserSubscriptionDetails/userSubscriptionSlice";
import Comment from "./components/Comment";
import Loading from "../../Components/Loading/Loading";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import ShowsRow from "../../Components/ShowsRow/ShowsRow";
import { getShowDetails } from "../ShowDetailsScreen/service";
import ShowCard from "../../Components/ShowCard/ShowCard";
import PlayNextEpisode from "../../Components/Modals/PlayNextEpisode";
import Autoplay from "./components/Autoplay";
import { logDOM } from "@testing-library/react";
let debounce = true;
var videoStarted = false;
let seconds = 60;
let prevTime;
let videoPlaying = false;
const Videoplayer = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const userSubscriptionData = useSelector((state) => state?.userSubscription?.value);
  const sessionId = useSelector((state) => state?.sessionId?.value);
  const user = useSelector((state) => state?.user?.value);

  const [vPlayer, setVPlayer] = useState();
  const [videoUrl, setVideoUrl] = useState("");
  const [watchWithoutAds, setWatchWithoutAds] = useState(false);
  const [isContinueWatchingModal, setIsContinueWatchingModal] = useState(false);
  const [watchedDuration, setWatchedDuration] = useState();
  const [adUrl, setAdUrl] = useState();
  const [loading, setLoading] = useState(true);
  const [liveDetails, setLiveDetails] = useState();
  const [subtitleInfo, setSubTitleInfo] = useState();
  const [audiolanguageInfo, setAudiolanguageInfo] = useState();

  const [subscribedUser, setSubscribedUser] = useState(true);
  const [videoDetails, setVideoDetails] = useState();
  const [subscriptions, setSubscriptions] = useState();
  const [watchListStatus, setWatchListStatus] = useState();
  const [showVideo, setShowVideo] = useState(false);
  const [isAutoplayView, setIsAutoplayView] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState()
  const [currentLanguageId, setCurrentLanguageId] = useState()
  const { videoId, showId } = useParams();
  const [error, setError] = useState(true);
  const location = useLocation();
  const isLive = location?.state?.isLive;
  const eventId = location?.state?.eventId;
  const showDetails = location?.state?.showDetails
  const previousUrl = location?.state?.prevLocation ? location?.state?.prevLocation : "/home";
  const videoType = location?.state?.showDetails?.type === "linear_event" ? "event" : "video";
  const Data = location?.state?.data;
  const showVanityUrl = location?.state?.showVanityUrl;
  // const databaseRef = firebase.database().ref('comment');

  const playerRef = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
    sessionId,
  };

  let timeState;

  const fetchRedirectDetails = async () => {
    try {
      const response = await getRedirectDetails();

      if (response?.data?.data?.type === 'INTERNAL' && response?.data?.data?.url) {
        navigate(response?.data?.data?.url);
      } else {
        userCheck();
      }
    } catch (error) {
      setError(error);
    }
  };


  const userCheck = () => {
    if (projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "true") {
      if (user) {
        fetchVideoDetails(videoId);
      } else {
        navigate("/login", {
          state: { path: location?.pathname, showId: showId },
        });
      }
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    setVPlayer(null)
    setLoading(true)
    fetchRedirectDetails()
  }, [location?.pathname]);

  useEffect(() => {

    if (videoDetails) {
      if (videoDetails?.video_id) {
        const isSubscribedUser =
          userSubscriptionData?.data?.length > 0 ? subscriptionCheck(userSubscriptionData?.data, subscriptions) : false;
        setSubscribedUser(isSubscribedUser);
        if (videoDetails?.free_video) {
          if (!isSubscribedUser) {
            if (projectInfo?.projectConfig?.config?.SUBSCRIPTION_REQUIRED === "false") {
              setSubscribedUser(true);
              if (projectInfo?.projectConfig?.config?.AD_REQUIRED === "true") {
                setAdUrl(convertAdUrl(videoDetails, showDetails, appInfo, isLive));
                playVideo();
              } else {
                if (videoDetails?.watched_duration) {
                  checkContinueWatching(true)
                } else {
                  playVideo();
                }
              }
            } else {
              if (subscriptions?.length === 0) {
                setSubscribedUser(true);
                if (videoDetails?.watched_duration) {
                  checkContinueWatching(true)
                } else {
                  playVideo();
                }
                // setAdUrl(convertAdUrl(videoDetails,showDetails,appInfo))
              } else {
                if (projectInfo?.projectConfig?.config?.AD_REQUIRED === "true") {
                  setWatchWithoutAds(true);
                } else {
                  setSubscribedUser(false);
                }
              }
            }
          } else {
            setSubscribedUser(true);
            if (videoDetails?.watched_duration) {
              checkContinueWatching(true)
            } else {
              playVideo();
            }
          }
        } else {
          if (!isSubscribedUser) {
            if (projectInfo?.projectConfig?.config?.SUBSCRIPTION_REQUIRED === "false") {
              setSubscribedUser(true);
              if (videoDetails?.watched_duration) {
                checkContinueWatching(true)
              } else {
                playVideo();
              }
            } else {
              setSubscribedUser(false);
              setShowVideo(false);
            }
          } else {
            setSubscribedUser(true);
            if (videoDetails?.watched_duration) {
              checkContinueWatching(true)
            } else {
              playVideo();
            }
          }
        }
      }
    }
  }, [videoDetails]);

  const checkContinueWatching = (check) => {
    if (!timeState) {
      setIsContinueWatchingModal(check);
    } else {
      setWatchedDuration(timeState)
      playVideo(timeState)
    }

  }



  let language_VideoID;
  const handleLanguageChange = async (selectedLanguageProps) => {

    setSelectedLanguage(selectedLanguageProps.language_name)
    language_VideoID = selectedLanguageProps.video_id;

    // let eventChange = "POP03"

    // const updateResponse = await onVideoPlayFunction(
    //   appInfo,
    //   selectedLanguageProps,
    //   eventChange,
    //   timeState,
    //   "0",
    //   location?.state?.categories,
    //   showDetails?.show_id
    // );
    // if (updateResponse.status == 200) {
    fetchVideoDetails(language_VideoID);
    // }

  };



  useEffect(() => {
    setVPlayer(null);
    if (videoUrl) {
      const videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        controlBar: {
          pictureInPictureToggle: false,
        },
        sources: [
          {
            src: `${videoUrl}`,
            type: "application/x-mpegURL",
          },
        ],
        // poster:require("../../../images/thumb.png")
      };

      setVPlayer(
        <VideoJS
          options={videoJsOptions}
          onReady={handlePlayerReady}
          adUrl={adUrl ? adUrl : null}
          subtitles={subtitleInfo}
          audiolanguage={audiolanguageInfo}
          onLanguageChange={handleLanguageChange}
          selectedLanguage={selectedLanguage}
          currentLanguageId={currentLanguageId}
        />
      );
    }
  }, [videoUrl]);

  const subscriptionCheck = (userSub, videoSub) => {
    const hasMatch = userSub.some((user) => videoSub.some((video) => user.sub_id === video.subscription_id));
    return hasMatch;
  };

  const playVideo = async (watchedLength) => {
    setShowVideo(true);

    setWatchedDuration(watchedLength);

    setSubTitleInfo(videoDetails?.subtitles);
    setAudiolanguageInfo(videoDetails.audio_languages);
    const arr = videoDetails?.video_name?.split("/").reverse();
    const playerTokenData = await fetchPlayerToken(arr[1]);

    if (playerTokenData) {
      setVPlayer(null);
      setVideoUrl(
        "https://api.gizmott.com/api/v1/playlistV2/playlist.m3u8?id=" +
        arr[1] +
        "&token=" +
        playerTokenData +
        "&type=" +
        videoType +
        "&pubid=" +
        projectInfo?.projectConfig?.pubid
      );
    }
  };

  const fetchPlayerToken = async (videoId) => {
    const playerTokenResponse = await getPlayerToken(appInfo, videoId);
    if (playerTokenResponse?.status === 200) {
      return playerTokenResponse?.data?.data;
    }
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    player.on("play", () => {
      let event = videoStarted == true ? "POP09" : "POP02";
      videoStarted = true;
      videoPlaying = true;
      updateVideoAnalytics(event, player.currentTime());
    });
    player.on("loadedmetadata", () => {

      player.currentTime(watchedDuration.toString());
  
      const closedCaption = localStorage.getItem('cc');
      if (closedCaption) {
        const checkTracks = setInterval(() => {
          const textTracks = Array.from(player.textTracks());
          const trackToEnable = textTracks.find(track => track.language === closedCaption);
  
          if (trackToEnable) {
            trackToEnable.mode = 'showing'; 
            clearInterval(checkTracks);
          }
        }, 100); 
      }

      player.on("texttrackchange", () => {
        const selectedTrack = Array.from(player.textTracks()).find(track => track.mode === "showing");
        if (selectedTrack) {
          localStorage.setItem('cc', selectedTrack.language);
        }else {
          localStorage.removeItem("cc");
        }
      });
    });

    player.on("timeupdate", function (e) {
      let event = "POP03";
      videoPlaying = true;
      var hasPlayedTime = player.currentTime();
      var intPlayedTime = parseInt(hasPlayedTime, 10);

      var isSixty = intPlayedTime % seconds === 0 && intPlayedTime !== 0;
      if (isSixty && debounce) {
        debounce = false;
        prevTime = intPlayedTime;
        updateVideoAnalytics(event, player.currentTime());
      } else {
        if (debounce == false && prevTime != intPlayedTime) {
          debounce = true;
        }
      }
    });

    player.on("pause", () => {
      let event = "POP04";
      videoPlaying = true;
      updateVideoAnalytics(event, player.currentTime());
    });
    player.on("ended", () => {
      let event = "POP05";
      videoPlaying = false;
      // episodeCount = findIndex() + 1;
      updateVideoAnalytics(event, player.currentTime());
      if (videoDetails?.up_next?.length > 0) {
        setIsAutoplayView(true);
      }
    });
    player.on("dispose", () => {
      videoPlaying = false;
      videoStarted = false;
      player.pause();
    });
    player.on('keydown', (e) => {
      if (e.which === 32 || e.which === 13) {
        e.preventDefault();
        if (player.paused()) {
          player.play();
        } else {
          player.pause();
        }
      }
    });

    player.on('keydown', (e) => {
      if (e.which === 37) {
        e.preventDefault();
        player.currentTime(player.currentTime() - 10);
      } else if (e.which === 39) {
        e.preventDefault();
        player.currentTime(player.currentTime() + 10);
      }
    });
    player.on('keydown', (e) => {
      if (e.which === 70 || e.which === 102) {
        e.preventDefault();
        player.requestFullscreen();
      }
    });

    player.on('keydown', (e) => {
      if (e.which === 38) {
        e.preventDefault();
        player.volume(player.volume() + 1);
      } else if (e.which === 40) {
        e.preventDefault();
        player.volume(player.volume() - 0.1);
      }
    });

    player.on('keydown', (e) => {
      if (e.which === 77 || e.which === 109) {
        e.preventDefault();
        player.muted(!player.muted());
      }
    });
    player.ready(() => {
      player.el().focus();
    });
    
  };

  const updateVideoAnalytics = async (event, time) => {
    timeState = time
    const updateResponse = await onVideoPlayFunction(
      appInfo,
      videoDetails,
      event,
      time,
      "0",
      location?.state?.categories,
      showDetails?.show_id
    );
  };

  const fetchVideoDetails = async (videoId) => {
    try {
      await fetchUserSubscriptionDetails();
      const response = await getVideoDetails(videoId, appInfo);
      if (response?.status === 200) {
        setCurrentLanguageId(response?.data?.data?.language_id)
        const videoSubResponse = await fetchVideoSubscription(response?.data?.data?.video_id);
        if (videoSubResponse?.status === 200) {
          setVideoDetails(response?.data?.data);
          setSubscriptions(videoSubResponse?.data?.data);
          setError(false)
          setLoading(false)
          if (response?.data?.data?.watchlist_flag === 1) setWatchListStatus("added");
        } else {
          setWatchListStatus("removed");
        }
      }
      else {
        setError(true); 
        setLoading(false);
      }
    } catch (err) {
      setError(true);
      setLoading(false);
    }
  };

  const fetchUserSubscriptionDetails = async () => {
    const userSubResponse = await getUserSubscription(appInfo);
    if (userSubResponse?.status === 200) {
      dispatch(
        getUserSubscriptionData({
          userSubscription: userSubResponse?.data,
        })
      );
    }
  };

  const fetchVideoSubscription = async (videoId) => {
    const type = "video";
    try {
      return await getVideoSubscription(appInfo, videoId, type);
    } catch (err) { }
  };

  const backButtonHandler = () => {
    navigate(previousUrl, { state: { showId: showDetails?.show_id } });
  };

  const searchHandler = (value, type) => {
    navigate(`/search?query=${value}`, { state: { type } });
  };

  const watchListHandler = (flag) => {
    updateWatchlist(videoDetails?.video_id, flag);
  };


  const updateWatchlist = async (videoId, flag) => {
    try {
      const response = await updateVideoWatchlistData(appInfo, videoId, flag);

      if (response?.status === 200) {
        if (flag === 1) {
          toast.success("Added to mylist", {
            position: "bottom-center",
            toastId: "success1",
          });
          setWatchListStatus("added");
        } else {
          toast.success("Removed from mylist", {
            position: "bottom-center",
          });
          setWatchListStatus("removed");
        }
      }
    } catch (err) { }
  };
  if (loading) {
    return <Loading />;
  }
  const goBack = () => {
    navigate(`/show-details/${ videoDetails?.show_vanity_url}`, {
      state: {
        showId: showDetails?.show_id ? showDetails?.show_id : Data?.show_id,
        type: showDetails?.type
      }
    });
  };
  
  if (error) {
    navigate("/404");
  }

  return (
    <div className="videoPlayer">
      <div className="wrapper">
        {watchWithoutAds && (
          <WatchWithoutAdsModal
            setWatchWithoutAds={setWatchWithoutAds}
            showDetails={showDetails}
            appInfo={appInfo}
            setAdUrl={setAdUrl}
            videoDetails={videoDetails}
            playVideo={playVideo}
            setIsContinueWatching={setIsContinueWatchingModal}
          />
        )}
        {isContinueWatchingModal && (
          <ContinueWatchingModel
            setIsContinueWatchingModal={setIsContinueWatchingModal}
            videoDetails={videoDetails}
            playVideo={playVideo}
            showDetails={showDetails}
            appInfo={appInfo}
            setAdUrl={setAdUrl}
          />
        )}
        {/* {
          isAutoplayView && (
            <PlayNextEpisode 
              episodes={videoDetails?.up_next}
              setIsAutoplayView={setNextEpisodeModal}
            />
          )
        } */}
        <div className="arrowContainer" onClick={goBack}>
          <h3>
            <LeftArrow />
          </h3>
        </div>

        <div className="playerContainer">
          {vPlayer && vPlayer}
          {
            isAutoplayView &&
            <Autoplay episodes={videoDetails?.up_next} setIsAutoplayView={setIsAutoplayView} />
          }
          {!subscribedUser && (
            <div className="donationContainer">
              <div className="bannerContainer">
                <div className="overlay"></div>
                {imageUrlCheck(videoDetails?.thumbnail_350_200) === true ? (
                  <img src={videoDetails?.thumbnail_350_200} alt="Banner" />
                ) : (
                  <img src={process.env.REACT_APP_IMAGE_URL + videoDetails?.thumbnail_350_200} alt="Banner" />
                )}
                <div className="donate">
                  <h1>THIS VIDEO IS CURRENTLY ONLY AVAILABLE TO DONORS.CONTACT SUPPORT FOR HELP.</h1>
                  <div className="buttonContainer">
                    <button onClick={() => window.open("https://redeemtv.com/donate", "_self")}>DONATE</button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="videoMetaData">
          <div className="head">
            <h1 className="videoTitle">{videoDetails?.video_title}</h1>
            <div className="buttonContainer">
              {watchListStatus === "added" ? (
                <button className="myList" onClick={() => watchListHandler(0)}>
                  Remove from My List
                </button>
              ) : (
                <button className="myList" onClick={() => watchListHandler(1)}>
                  Add to My List
                </button>
              )}
              {/* <button className="share">Share</button> */}
            </div>
          </div>
          <div className="categoriesAndRating">
            <div className="categories">
              {videoDetails?.category_name?.map((item, index) => (
                <span className="category">{item}</span>
              ))}
            </div>

            {/* <span className="rating">PG</span> */}
          </div>
          <p className="description">
            {videoDetails?.video_description && parse(videoDetails?.video_description?.split("\n")?.join("<br>"))}
          </p>

          <div className="extraInfoContainer">
            {videoDetails?.video_tags?.length > 0 && (
              <div className="metaItem">
                <h6 className="subHeading">Tags</h6>
                <div className="items oneRow">
                  {videoDetails?.video_tags?.length > 0 &&
                    videoDetails?.video_tags?.map((item, index) => (
                      <span className="item" onClick={() => searchHandler(item, "tags")}>
                        {item}
                        <span className="comma">,</span>
                      </span>
                    ))}
                </div>
              </div>
            )}
            {videoDetails?.cast?.length > 0 && (
              <div className="metaItem">
                <h6 className="subHeading">Cast</h6>
                <div className="items">
                  {videoDetails?.cast?.map((item, index) => (
                    <div className="item" key={item?.id}>
                      <span className="itemInfo" onClick={() => searchHandler(item?.name, "cast")}>
                        {item?.name}
                      </span>
                      <span className="itemLabel">{item?.role}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {videoDetails?.crew?.length > 0 && (
              <div className="metaItem">
                <h6 className="subHeading">Crew</h6>
                <div className="items">
                  {videoDetails?.crew?.map((item, index) => (
                    <div className="item" key={item?.id}>
                      <span className="itemInfo" onClick={() => searchHandler(item?.name, "crew")}>
                        {item?.name}
                      </span>
                      <span className="itemLabel">{item?.role}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

      </div>

      <div className="wrapper">
        {videoDetails?.up_next?.length > 0 && (
          <div className="upNextContainer">          
            <h1 className="heading">Up Next in {videoDetails?.season && `${videoDetails?.show_name}`} </h1>
            <div className="upNext" onClick={() => setIsAutoplayView(false)}>
              {videoDetails?.up_next?.map((video) => (
                <div className="item">
                  <ShowCard data={video} type="Episode" />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {videoDetails?.files?.length > 0 && (
        <div className="wrapper">
          <div className="files">
            <ShowsRow data={videoDetails?.files} type="pdf" />
          </div>
        </div>
      )}
      <span className="line"></span>
      <div className="wrapper">
        <Comment videoId={videoDetails?.video_id} />
      </div>
    </div>
  );
};

export default Videoplayer;
