import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import VideoJS from "../../Components/VideoJsPlayer/VideoJS";
import { useLocation, useNavigate } from "react-router-dom";
import { convertTimeToLocal, onVideoPlayFunction } from "../../utils/utils";
import { getLiveInfo } from "./service";
import { getEventInfo } from "./service";
import Loading from "../../Components/Loading/Loading";
import Autoplay from "../videoPlayer/components/Autoplay";
import parse from "html-react-parser";
import ShowCard from "../../Components/ShowCard/ShowCard";
import { getLiveSchedule } from "./service";

let debounce = true;
var videoStarted = false;
let seconds = 60;
let prevTime;
let lastLiveTriggerCheck = 0; // Timestamp for last check

const LivePlayerPage = () => {
  const [livePlayer, setLivePlayer] = useState()
  const [liveDetails, setLiveDetails] = useState()
  const [loading, setLoading] = useState()
  const [isAutoplayView, setIsAutoplayView] = useState(false);
  const [liveTrigger, setLiveTrigger] = useState(null);

  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const sessionId = useSelector((state) => state?.sessionId?.value);

  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
    sessionId: sessionId
  }
  const location = useLocation()
  const navigate = useNavigate();

  const eventId = location?.state?.eventId || localStorage.getItem("eventId")
  const playerRef = useRef(null);

  useEffect(() => {
    setLiveTrigger(true);
  }, [])

  useEffect(() => {

    liveTrigger === null && setLoading(true);

    if (liveTrigger) {
      
      if (eventId) {

        localStorage.removeItem("eventId");
        fetchEventInfo();

      } else {
        fetchLiveInfo()
      }


      setLiveTrigger(false);


    } else {
      // fail silently
    }

  }, [liveTrigger])


  useEffect(() => {
    if (liveDetails) {

      const videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        controlBar: {
          pictureInPictureToggle: false,
        },
        sources: [
          {
            // src: liveDetails?.live_url,
            src: liveDetails?.live_url,
            type: "application/x-mpegURL",
          },
        ],

      };
      setLivePlayer(<VideoJS onReady={handlePlayerReady} options={videoJsOptions} liveData={liveDetails} />)
    }
  }, [liveDetails])

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on("play", () => {
      let event = videoStarted == true ? "POP09" : "POP02";
      videoStarted = true;
      updateLiveAnalytics(
        eventId ? liveDetails : liveDetails?.now_playing,
        event,
        player.currentTime()
      );
    });

    player.on("timeupdate", function (e) {
      let event = "POP03";

      var hasPlayedTime = player.currentTime();
      var intPlayedTime = parseInt(hasPlayedTime, 10);
      var isSixty = intPlayedTime % seconds === 0 && intPlayedTime !== 0;

      // const localDate = new Date();
      // const currentTime = convertTimeToLocal(localDate);
      // const endTime = convertTimeToLocal(liveDetails?.now_playing?.end_time);

      // const trimmedCurrentTime = currentTime.trim();
      // const trimmedEndTime = endTime.trim();
      // const currentTimestamp = new Date().getTime();

      // if (trimmedCurrentTime >= trimmedEndTime && (currentTimestamp - lastLiveTriggerCheck >= 60000)) {

      //   lastLiveTriggerCheck = currentTimestamp;
      //   setLiveTrigger(true);
      // } else {
      //   // do nothing
      // }


      if (isSixty && debounce) {

        const localDate = new Date();
        const currentTime = convertTimeToLocal(localDate);
        const endTime = convertTimeToLocal(liveDetails?.now_playing?.end_time);

        const trimmedCurrentTime = currentTime.trim();
        const trimmedEndTime = endTime.trim();
        const currentTimestamp = new Date().getTime();

        if (trimmedCurrentTime >= trimmedEndTime) {

          lastLiveTriggerCheck = currentTimestamp;
          setLiveTrigger(true);
        } else {
          // do nothing
        }

        debounce = false;
        prevTime = intPlayedTime;
        updateLiveAnalytics(
          eventId ? liveDetails : liveDetails?.now_playing,
          event,
          player.currentTime()
        );
      } else {
        if (debounce == false && prevTime != intPlayedTime) {
          debounce = true;
        }
      }
    });

    player.on("pause", () => {

      let event = "POP04";
      updateLiveAnalytics(
        eventId ? liveDetails : liveDetails?.now_playing,
        event,
        player.currentTime()
      );
    });
    player.on("ended", () => {
      let event = "POP05";
      updateLiveAnalytics(
        eventId ? liveDetails : liveDetails?.now_playing,
        event,
        player.currentTime()
      );
    });


    player.on("dispose", () => {
      videoStarted = false;
      // player.pause();
    });
  };

  const updateLiveAnalytics = async (nowPlaying, event, time) => {
    const isLive = "1";
    const updateResponse = await onVideoPlayFunction(
      appInfo,
      nowPlaying,
      event,
      time,
      isLive
    );
  };

  const fetchLiveInfo = async () => {
    try {
      const response = await getLiveInfo(appInfo);
      // setLiveDetails();
      setLiveDetails(response?.data?.data[0])
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }
  const fetchEventInfo = async () => {
    try {
      const response = await getEventInfo(appInfo, eventId);
      // setLiveDetails();
      setLiveDetails(response?.data?.data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }
  const watchOndemandHandler = () => {
    navigate(`/videos/${liveDetails?.now_playing?.vanity_url}`);
  };

  if (loading) {
    return (
      <Loading />
    )
  }

  return (
    <div className="liveplayerContainer">
      <div className="wrapper">
        <div className="playerContainer">
          {livePlayer}
          {
            isAutoplayView &&
            <Autoplay episodes={liveDetails?.up_next} setIsAutoplayView={setIsAutoplayView} />
          }
          <div className="bottom">
            <div className="details">
              <div className="left">
                <div className="HeadingButtonContainer">
                  <h1 className="heading">Now Playing</h1>
                  <div className="buttonContainer">
                    {
                      <button className="myList" onClick={() => watchOndemandHandler()}>
                        Watch on-demand
                      </button>
                    }
                  </div>
                </div>
                <h1 className="title">
                  {liveDetails?.now_playing?.video_title}
                </h1>


                <span className="time">
                  {convertTimeToLocal(liveDetails?.now_playing?.start_time)
                    .toString()
                    .toLowerCase()}
                </span>
                <p className="description">
                  {liveDetails?.now_playing?.video_description}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="videoMetaData">
          <div className="head">
            <h1 className="videoTitle"> {liveDetails?.channel_name}</h1>
            <div className="buttonContainer">
              {
                <button className="myList" onClick={() => watchOndemandHandler()}>
                  Watch on-demand
                </button>
              }
            </div>
          </div>
          <p className="description">
            {liveDetails?.description && parse(liveDetails?.description?.split("\n")?.join("<br>"))}
          </p>
        </div>
      </div>
      <div className="wrapper">
        {liveDetails?.up_next && (
          <div className="upNextContainer">
            <h1 className="heading" >Up Next in {liveDetails?.channel_name} </h1>
            <div className="upNext" onClick={() => setIsAutoplayView(false)}>
              {liveDetails?.up_next?.map((video, index) => (
                <div className="item" key={index}>
                  <ShowCard data={video} type="Episode" />
                </div>
              ))}

            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default LivePlayerPage;