import React, { useEffect, useState } from "react";
import Loading from "../../Components/Loading/Loading";
import { useNavigate } from "react-router-dom";
import * as service from "./service";
import { async } from "@firebase/util";

const RedirectShowCategoryInformation = () => {

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchRedirectDetails()
  }, []);

  const fetchRedirectDetails = async () => {

    const showDetailsResponse = await service.getRedirectDetails();
    if (showDetailsResponse?.data?.data?.type == 'INTERNAL') {
      navigate(showDetailsResponse?.data?.data?.url);
    } else if (showDetailsResponse?.data?.data?.type == 'EXTERNAL') {
      window.location.href = showDetailsResponse?.data?.data?.url
    } else {
      navigate('/404')
    }
  }

  return (<>
    {loading ? <Loading /> : <></>}
  </>);
};

export default RedirectShowCategoryInformation;
