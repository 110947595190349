import React, { useEffect, useState, Fragment } from "react";
import {  useSelector } from "react-redux";

const MagazineCard = ({ data,url, metaData}) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const userSubscriptionData = useSelector(
    (state) => state?.userSubscription?.value
  );
  const [thumbnailOrientation, setThumbnailOrientation] = useState("");
  const [isCardHovered, setIsCardHovered] = useState(false);
  const [imageURL,setImageURL] = useState(projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE)
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };


  useEffect(() => {
    if (
      projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT"
    ) {
      setThumbnailOrientation("PORTRAIT");
    } else {
      setThumbnailOrientation("LANDSCAPE");
    }
  }, [projectInfo]);
  

  const replaceImage = (error) => {
    error.target.src = projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE
  }

  const handleCardHover = () => {
    setIsCardHovered(true);
  };
  return (
    <div
      className={ `magazineCardContainer cardHover `}
      title={data?.show_name}
      >
      <div className="showMoreOverlay" onClick={()=>window.open(data?.url,'_blank')}>
      
      </div>
     
      <div className={`square ${isCardHovered ? 'cardHovered' : ''}`} onClick={() => window.open(data?.url, '_blank')} onMouseEnter={handleCardHover}>
        <div className="imageContainer">  
          <img
            src={imageURL}
            key={data?.show_id}
            onError={(e) => replaceImage(e)}
            onLoad={(e) => setImageURL(data?.logo)}
            alt="ShowImage"
          />
        </div> 
        <div className="metaData">
         <h1 className="title">{data?.show_name}</h1>
        
         <div className="buttons">
            <button onClick={()=>window.open(data?.url,'_blank')}>  
                 Download
            </button>
            </div>
        </div>
      </div>
     
    </div>
    
  );
};

export default MagazineCard ;
