import React, { useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";
import { getTVCode } from "./service";
import { useSelector } from "react-redux";
const LinkTvApp = () => {
  const projectInfo=useSelector((state)=>state?.projectInfo?.value);
  const accessToken=useSelector((state)=>state?.accessToken?.value);
  const [code,setCode] = useState()
  const [loading,setLoading] = useState(true)

  const countDownRef = useRef();
  const appInfo={
    projectDetails:projectInfo,
    accessToken:accessToken
  }

  useEffect(()=>{
    setLoading(true)
    getCode()
  },[])

  const regenerateHandler = () =>{
    setLoading(true)
    getCode()
  }

  const Completionist = () => (
    <div className="authCodeContainer">
    <h1 className="heading">Link TV App</h1>
    <p>Enter this code on your smart TV.</p>
    <div className="authCodeField expired">{code}</div>
    <div className="codeExpired">
      <span>Code Expired!</span>
      <div className="regenerateButton">
        <button onClick={regenerateHandler}>REGENERATE CODE</button>
      </div>
    </div>
   
  </div>
  );

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <div className="authCodeContainer">
        <h1 className="heading">Link TV App</h1>
        <p>Enter this code on your smart TV.</p>
        {
            loading ? (<span className="loading">Generating Code . . .</span>)
            :
            (
                <>
                <div className="authCodeField">{code}</div>
                <span className="timer">
                Your code expires on {minutes}:{seconds}
                </span>
                </>

            )
        }
      </div>
       
      );
    }
  };

  const getCode = async () => {
    const response = await getTVCode(appInfo)
    if(response.status === 200){
        setLoading(false)
        setCode(response?.data?.code)
        countDownRef.current.getApi().start();
    }else{
        setLoading(false)
    }
  }
  return (
    <div className="linkTvApp">
     
      <Countdown
            date={Date.now() +5 * 6 * 10000} 
            renderer={renderer}
            autoStart={false} 
            ref={(count) => (countDownRef.current = count)}

        />
    </div>
  );
};

export default LinkTvApp;
