import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as Closeicon } from "../../assets/Icons/cross.svg";
import { getPlayerToken } from "../../network/service";
import VideoJS from "../VideoJsPlayer/VideoJS";

const TrailerModal = ({teaser,showName,setIsTrailer,appInfo}) => {
    const [trailerUrl,setTrailerUrl] = useState("")
    const [videoPlayer,setVideoPlayer] = useState()
    const accessToken = useSelector((state) => state?.accessToken?.value);
    const refOne = useRef(null)
    const [open, setOpen] = useState(false);

    useEffect(()=>{
        fetchPlayerToken()
    },[])

    useEffect(()=>{
        setVideoPlayer(null);
        if (trailerUrl) {
          const videoJsOptions = {
            autoplay: true,
            controls: true,
            responsive: true,
            fluid: true,
            controlBar: {
              pictureInPictureToggle: false,
            },
            sources: [
              {
                src: `${trailerUrl}`,
                type: "application/x-mpegURL",
              },
            ],
            // poster:require("../../../images/thumb.png")
          };
    
          setVideoPlayer(
            <VideoJS
              options={videoJsOptions}
            //   onReady={handlePlayerReady}
            />
          );
        }
    },[trailerUrl])

    useEffect(()=>{
      document.addEventListener("click" ,handleClickOutSide,true)
    },[]);

    const handleClickOutSide = (e)=>{
      if(!refOne?.current?.contains(e.target)){
        setOpen(false);
        setIsTrailer(false)
      }
      else{
        console.log("clicked inside......");

      }
    }
    const fetchPlayerToken = async () => {
      let arr = teaser?.split("/").reverse();
      console.log(arr,"teaser");

        const playerTokenResponse = await getPlayerToken(appInfo,arr[1]);
        if (playerTokenResponse?.status === 200) {
            setTrailerUrl(
              "https://api.gizmott.com/api/v1/playlistV2/playlist.m3u8?id=" +
                arr[1] +
                "&token=" +
                playerTokenResponse?.data?.data +
                "&type=trailer&pubid="+ appInfo?.projectDetails?.projectConfig?.pubid
            );
          return playerTokenResponse?.data?.data;
        }
      };
    const trailerHandler = () => {
      setIsTrailer(false)
    }
  return (
    <div className="trailerModal">
      <div className="overlayModal"></div>
      <div className="head">
        <div className="left">
            <h4 className="heading">Trailer</h4>
            <h1 className="title">{showName}</h1>
            {/* <span className="director">Director</span>
            <span className="year">2005</span> */}
        </div>

        <div className="right">
            <div className="closeIcon" onClick={()=>trailerHandler("close")}>
                <Closeicon/>
            </div>
        </div>
      </div>
   <div className="trailorContainer" ref={refOne}  onClick={()=>{setOpen(!open)}}>
      <div className="trailerPlayer" >
        {
            videoPlayer
        }
        
      </div>
      </div>
    </div>
  );
};

export default TrailerModal;
