import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { loginWithTVCode } from "./service";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const TvActivationReverse = () => {
    const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const [inputValue, setInputValue] = useState("");
  const location = useLocation();
  const navigate = useNavigate()  
  const params = new URLSearchParams(location?.search);
  let code = params?.get("code");

  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  useEffect(() => {
    if (code) {
      setInputValue(code);
    }
  }, [code]);

  const registerHandler = (e) => {
    e.preventDefault()
    if(inputValue){
        updateTVActivationCode()
    }else{
        toast.error("Please fill the field!", {
            position: "bottom-center",
          });
    }
  };

  const updateTVActivationCode = async () => {
    try {
      const response = await loginWithTVCode(appInfo,inputValue);
      if(response?.status === 200){
        toast.success(response?.data?.message, {
            position: "bottom-center",
          });
          setInputValue("")
          setTimeout(() => {
              navigate("/home")
          }, 1000);
      }
    } catch (err) {}
  };
  return (
    <div className="tvActivationReverse">
      <div className="wrapper">
        <div className="topHeader">
          <h1>Sign up a device</h1>
        </div>
        <div className="bottomContainer">
          <h2>Sign up your compatible TV or device</h2>
          <p>Enter the code shown on your TV or device to register it with your {projectInfo?.projectConfig?.config?.TAB_TITLE} account.</p>

          <div className="inputContainer">
            <form>
              <label htmlFor="registrationCode">Registration code:</label>
              <input
                type="text"
                id="registrationCode"
                value={inputValue || ""}
                placeholder="e.g. HQ2W4Z"
                onChange={(e) => setInputValue(e.target.value)}
              />
              <button type="submit" onClick={(e)=>registerHandler(e)}>Sign up Device</button>
            </form>
            <p className="terms">
              By continuing, you agree to the <span onClick={()=> navigate("/terms-and-conditions")}>{projectInfo?.projectConfig?.config?.TAB_TITLE} Terms and Conditions</span>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TvActivationReverse;
